import { blue, transparent } from '@a-type/ui/styles/theme/colors';
import { LocationOn } from '@mui/icons-material';
import { Box } from '@mui/material';
import { AdvancedMarker, Map } from '@vis.gl/react-google-maps';
import { FC, useEffect, useState } from 'react';

interface ITaxAssessorsDocumentMapProps {
  coordinates: [number, number];
}

export const TaxAssessorsDocumentMap: FC<ITaxAssessorsDocumentMapProps> = ({
  coordinates,
}: ITaxAssessorsDocumentMapProps) => {
  const [defaultCenter, setDefaultCenter] = useState<{ lat: number; lng: number } | undefined>();
  const [hovered, setHovered] = useState(false);

  useEffect(() => {
    if (!coordinates) return;

    setDefaultCenter({
      lat: coordinates[0],
      lng: coordinates[1],
    });
  }, [coordinates]);

  return (
    <Box
      className="google-map-wrapper"
      sx={{
        minHeight: '352px',
        minWidth: '422px',
      }}
    >
      {defaultCenter && (
        <Map
          defaultCenter={defaultCenter}
          defaultZoom={12}
          disableDefaultUI
          mapId="TAX_ASSESSORS_DOCUMENT_MAP"
          maxZoom={18}
        >
          <AdvancedMarker
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
            position={defaultCenter}
            style={{
              cursor: 'pointer',
            }}
          >
            <Box
              sx={{
                alignItems: 'center',
                backgroundColor: 'blue.05',
                borderRadius: '50%',
                display: 'flex',
                flexDirection: 'column',
                height: 40,
                justifyContent: 'center',
                outline: hovered ? '6px solid' : 'none',
                outlineColor: transparent(blue['05'], 0.3),
                width: 40,
              }}
            >
              <LocationOn
                sx={{
                  color: 'gray.00',
                  fontSize: 24,
                }}
              />
            </Box>
          </AdvancedMarker>
        </Map>
      )}
    </Box>
  );
};
