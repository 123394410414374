import {
  IAppendDataRequest,
  IDocumentWithNavigation,
  IPaginationDto,
  IUpdateListDto,
  IUploadListDto,
} from '@a-type/dtos';
import { IDocument, IList } from '@a-type/interfaces';

import apiMiddleware from './api-middleware';

export const listApi = apiMiddleware.injectEndpoints({
  endpoints: (builder) => ({
    appendData: builder.mutation<
      IList,
      { data: IAppendDataRequest; documentId?: string; id: string }
    >({
      invalidatesTags: (result, error, data) => [
        { id: data.id, type: 'List' as const },
        { id: 'LIST', type: 'List' as const },
        { id: 'CREDIT_BALANCE', type: 'Account' as const },
        { id: 'TRANSACTIONS', type: 'Transactions/User' as const },
        ...(data.documentId ? [{ id: data.documentId, type: 'Document' as const }] : []),
        { id: 'DOCUMENTS', type: 'Bucket' as const },
      ],
      query: ({ data, documentId, id }) => ({
        data,
        method: 'POST',
        url: `/list/${id}/append-data/${documentId ?? ''}`,
      }),
    }),
    buyList: builder.mutation<IList, IList>({
      invalidatesTags: (result) => [
        ...(result ? [{ id: result._id, type: 'List' as const }] : []),
        { id: 'LIST', type: 'List' as const },
        { id: 'TOTAL_COUNT', type: 'List' as const },
        { id: 'CREDIT_BALANCE', type: 'Account' as const },
        { id: 'TRANSACTIONS', type: 'Transactions/User' as const },
      ],
      query: (data) => ({ data, method: 'POST', url: `/list/buy/` }),
    }),
    buyMoreRecords: builder.mutation<
      IList,
      {
        count: number;
        id: string;
      }
    >({
      invalidatesTags: (result, error, { id }) => [
        { id, type: 'List' as const },
        { id: 'LIST', type: 'List' as const },
        { id: 'CREDIT_BALANCE', type: 'Account' as const },
        { id: 'TRANSACTIONS', type: 'Transactions/User' as const },
      ],
      query: ({ count, id }) => ({ method: 'POST', url: `/list/buy-more/${id}/records/${count}/` }),
    }),
    getDocumentById: builder.query<IDocument, { documentId: string; listId: string }>({
      providesTags: (result) => (result ? [{ id: result._id, type: 'Document' as const }] : []),
      query: ({ documentId, listId }) => ({
        url: `/list-documents/${listId}/document/${documentId}`,
      }),
    }),
    getDocumentByIdWithNavigation: builder.query<
      IDocumentWithNavigation,
      { documentId: string; listId: string }
    >({
      providesTags: (result) =>
        result ? [{ id: result.document._id, type: 'Document' as const }] : [],
      query: ({ documentId, listId }) => ({
        url: `/list-documents/${listId}/document/${documentId}/navigation`,
      }),
    }),
    getDocumentByIndex: builder.query<IDocument, { index: number; listId: string }>({
      query: ({ index, listId }) => ({
        params: { index },
        url: `/list-documents/${listId}/document`,
      }),
    }),
    getDocuments: builder.query<
      IPaginationDto<any>,
      { buyingDate?: string; id: string; limit?: number; page?: number }
    >({
      query: ({ buyingDate, id, limit, page }) => ({
        params: { buyingDate, limit, page },
        url: `/list-documents/${id}`,
      }),
    }),
    getList: builder.query<IList, string>({
      providesTags: (result) => (result ? [{ id: result._id, type: 'List' as const }] : []),
      query: (id) => ({ url: `/list/${id}/` }),
    }),
    getLists: builder.query<
      IPaginationDto<IList>,
      { limit?: number; manualUpload?: boolean; page?: number }
    >({
      providesTags: [{ id: 'LIST', type: 'List' as const }],
      query: (params) => ({ params, url: '/list/' }),
    }),
    refreshAvailableRecords: builder.query<IList, string>({
      providesTags: (result) => (result ? [{ id: result._id, type: 'List' as const }] : []),
      query: (id) => ({ url: `/list/with-available-count/${id}/` }),
    }),
    updateList: builder.mutation<IList, IUpdateListDto>({
      invalidatesTags: (result) =>
        result
          ? [
              { id: result._id, type: 'List' as const },
              { id: 'LIST', type: 'List' as const },
            ]
          : [{ id: 'LIST', type: 'List' as const }],
      query: (data) => ({ data, method: 'POST', url: `/list/${data._id}/` }),
    }),
    uploadList: builder.mutation<
      IList,
      {
        file: File;
        model: IUploadListDto;
      }
    >({
      invalidatesTags: [{ id: 'LIST', type: 'List' as const }],
      query: ({ file, model }) => {
        const formData = new FormData();
        formData.append('file', file, file.name);
        formData.append('list', JSON.stringify(model));
        return {
          data: formData,
          headers: { 'Content-Type': file.type },
          method: 'PUT',
          url: '/list/upload',
        };
      },
    }),
  }),
});

export const {
  useAppendDataMutation,
  useBuyListMutation,
  useBuyMoreRecordsMutation,
  useGetDocumentByIdQuery,
  useGetDocumentByIdWithNavigationQuery,
  useGetDocumentByIndexQuery,
  useGetDocumentsQuery,
  useGetListQuery,
  useGetListsQuery,
  useRefreshAvailableRecordsQuery,
  useUpdateListMutation,
  useUploadListMutation,
} = listApi;
