type CustomColor = {
  '00'?: string;
  '01'?: string;
  '02'?: string;
  '03'?: string;
  '04'?: string;
  '05'?: string;
  '06'?: string;
  '07'?: string;
  '08'?: string;
  '09'?: string;
  '10'?: string;
};

declare module '@mui/material/styles' {
  interface Palette {
    blue: CustomColor;
    gray: CustomColor;
    green: CustomColor;
    orange: CustomColor;
    red: CustomColor;
  }
  interface PaletteOptions {
    blue: CustomColor;
    gray: CustomColor;
    green: CustomColor;
    orange: CustomColor;
    red: CustomColor;
  }
}

export const transparent = (color: string, opacity: number) => {
  return `${color}${Math.round(opacity * 255)
    .toString(16)
    .toUpperCase()}`;
};

export const gray = {
  '00': '#FFFFFF',
  '01': '#FCFCFD',
  '02': '#F2F4F7',
  '03': '#EAECF0',
  '04': '#D0D5DD',
  '05': '#98A2B3',
  '06': '#667085',
  '07': '#475467',
  '08': '#344054',
  '09': '#1D2939',
  '10': '#101828',
};

export const blue = {
  '01': '#E9F5FF',
  '02': '#B7DFFF',
  '03': '#86C9FF',
  '04': '#54B3FF',
  '05': '#2196F3',
  '06': '#107CD1',
  '07': '#0363AF',
  '08': '#004F8D',
  '09': '#003C6B',
  '10': '#002949',
};

export const green = {
  '01': '#ECFFF5',
  '02': '#C1FFDE',
  '03': '#96FFC7',
  '04': '#6CFFB0',
  '05': '#3EF292',
  '06': '#26D076',
  '07': '#14AE5C',
  '08': '#068C45',
  '09': '#006A32',
  '10': '#004822',
};

export const orange = {
  '01': '#FFF1E5',
  '02': '#FFD8B7',
  '03': '#FFBF8A',
  '04': '#FFA55C',
  '05': '#FF8C2E',
  '06': '#EF6C00',
  '07': '#C65A00',
  '08': '#9D4700',
  '09': '#753500',
  '10': '#4C2200',
};

export const red = {
  '01': '#FFE9E9',
  '02': '#FFB8B7',
  '03': '#FF8785',
  '04': '#FF5653',
  '05': '#EC221F',
  '06': '#CA110E',
  '07': '#A80402',
  '08': '#860200',
  '09': '#640200',
  '10': '#420100',
};
