import { IDataSourceFilterComponentDto } from '@a-type/dtos';
import { DataSourceFieldComponentType, DataSourceFieldDataType } from '@a-type/enums';
import { IDataSourceField } from '@a-type/interfaces';
import { Switch } from '@a-type/ui/components';
import { useSelector } from '@a-type/ui/hooks';
import { useGetFilterGroupsQuery } from '@a-type/ui/stores/apis';
import globalStyles from '@a-type/ui/styles/global.styles';
import { Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useEffect, useState } from 'react';

import DataSourceDetailsFieldConfiguratorDateRangeSelect from './DataSourceDetailsFieldConfiguratorDateRangeSelect.component';
import DataSourceDetailsFieldConfiguratorFilterOptions from './DataSourceDetailsFieldConfiguratorFilterOptions.component';
import DataSourceDetailsFieldConfiguratorRangeSelect from './DataSourceDetailsFieldConfiguratorRangeSelect.component';

export interface DataSourceDetailsFieldConfiguratorFilterProps {
  field: IDataSourceField;
  updateFieldValueHandler: (key: string, value: any) => void;
}

const DataSourceDetailsFieldConfiguratorFilter = (
  props: DataSourceDetailsFieldConfiguratorFilterProps,
) => {
  const { field, updateFieldValueHandler } = props;
  const { data: filtersGroups } = useGetFilterGroupsQuery();
  const { filtersComponents, lookupsMapping } = useSelector((state) => state.dataSource);

  const [components, setComponents] = useState<IDataSourceFilterComponentDto[]>([]);

  useEffect(() => {
    if (!field || !filtersComponents) {
      return;
    }

    const hasLookups = Object.keys(lookupsMapping).length > 0;
    const lookups = Object.keys(lookupsMapping).some((key) => key === field.name);

    // allow only components that match the field data type and MultiSelect allowed for lookups data
    const newComponents = filtersComponents
      .filter((item: IDataSourceFilterComponentDto) => item.dataTypes?.includes(field.dataType))
      .filter(
        (item: IDataSourceFilterComponentDto) =>
          !hasLookups || item.type !== DataSourceFieldComponentType.MULTI_SELECT || lookups,
      );
    setComponents(newComponents);

    if (newComponents.length === 0) {
      updateFieldValueHandler('componentType', '');
      updateFieldValueHandler('options', []);
    }
    // reset component type if it's not allowed for the field data type
    else if (!newComponents.some((item) => item.type === field.componentType)) {
      updateFieldValueHandler('componentType', newComponents[0].type);
      updateFieldValueHandler('options', []);
    }
  }, [field, filtersComponents, lookupsMapping]);

  return (
    <Box
      sx={{
        borderTop: '1px solid #e0e0e0',
        display: 'flex',
        gap: 2,
        justifyContent: 'space-between',
      }}
    >
      <Box sx={{ display: 'flex', flexBasis: '50%', flexDirection: 'column', gap: 2, pt: 1 }}>
        <Switch
          checked={field.isGroupedBy}
          label="Use for Narrowing"
          onChange={() => {
            updateFieldValueHandler('isGroupedBy', !field.isGroupedBy);
          }}
        />

        {(filtersGroups || []).length > 0 && (
          <FormControl>
            <InputLabel id={`filter-group-${field.name}-label`} size="small">
              Filter Group
            </InputLabel>
            <Select
              error={!field.filterGroup}
              id={`filter-group-${field.name}`}
              label="Filter Group"
              labelId={`filter-group-${field.name}-label`}
              onChange={(e: any) => {
                const { value } = e.target;
                updateFieldValueHandler('filterGroup', value);
              }}
              placeholder="Select Filter Group"
              size="small"
              sx={{ background: globalStyles.mainColors.whiteColor }}
              value={field.filterGroup || ''}
            >
              {(filtersGroups || []).map((item: any) => (
                <MenuItem key={item.code} value={item.code}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        {components.length > 0 && (
          <FormControl
            sx={{
              width: '100%',
            }}
          >
            <InputLabel id={`filter-component-${field.name}-label`} size="small">
              Filter Component
            </InputLabel>
            <Select
              disabled={components.length === 1}
              error={!field.componentType}
              id={`filter-component-${field.name}`}
              label="Filter Component"
              labelId={`filter-component-${field.name}-label`}
              onChange={(e: any) => {
                const { value } = e.target;
                updateFieldValueHandler('componentType', value);
              }}
              placeholder="Select Filter Component"
              size="small"
              sx={{ background: globalStyles.mainColors.whiteColor }}
              value={field.componentType || ''}
            >
              {components.map((item: any) => (
                <MenuItem key={item.type} value={item.type}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </Box>

      {(field.componentType === DataSourceFieldComponentType.MULTI_SELECT ||
        field.componentType === DataSourceFieldComponentType.OPTION_SELECT) && (
        <Box sx={{ flexBasis: '50%' }}>
          <DataSourceDetailsFieldConfiguratorFilterOptions
            field={field}
            updateFieldValueHandler={updateFieldValueHandler}
          />
        </Box>
      )}

      {field.componentType === DataSourceFieldComponentType.RANGE_SELECT &&
        field.dataType === DataSourceFieldDataType.NUMBER && (
          <Box sx={{ flexBasis: '50%' }}>
            <DataSourceDetailsFieldConfiguratorRangeSelect
              field={field}
              updateFieldValueHandler={updateFieldValueHandler}
            />
          </Box>
        )}

      {field.componentType === DataSourceFieldComponentType.RANGE_SELECT &&
        field.dataType === DataSourceFieldDataType.DATE && (
          <Box sx={{ flexBasis: '50%' }}>
            <DataSourceDetailsFieldConfiguratorDateRangeSelect
              field={field}
              updateFieldValueHandler={updateFieldValueHandler}
            />
          </Box>
        )}
    </Box>
  );
};

export default DataSourceDetailsFieldConfiguratorFilter;
