import { IDataSourceField } from '@a-type/interfaces';
import globalStyles from '@a-type/ui/styles/global.styles';
import { Box, TextField } from '@mui/material';

export interface DataSourceDetailsFieldConfiguratorRangeSelectProps {
  field: IDataSourceField;
  updateFieldValueHandler: (key: string, value: any) => void;
}

enum TValue {
  MAX = 'max',
  MIN = 'min',
  STEP = 'step',
}

const DataSourceDetailsFieldConfiguratorRangeSelect = (
  props: DataSourceDetailsFieldConfiguratorRangeSelectProps,
) => {
  const { field, updateFieldValueHandler } = props;

  const MIN_VALUE = -999999999999;
  const MAX_VALUE = 999999999999;

  const STEP_MIN_VALUE = 0;
  const STEP_MAX_VALUE = 1000000;

  const MAX_DECIMAL_PLACES = 3;

  const handleChange = (key: TValue) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value === '' ? undefined : Number(event.target.value);

    if (inputValue === undefined) {
      updateFieldValueHandler(key, undefined);
      return;
    }

    const roundedValue = parseFloat(inputValue.toFixed(MAX_DECIMAL_PLACES));

    let validatedValue: number | undefined;

    if (key === TValue.MIN) {
      validatedValue = roundedValue <= MIN_VALUE ? undefined : roundedValue;
    } else if (key === TValue.MAX) {
      validatedValue = roundedValue >= MAX_VALUE ? undefined : roundedValue;
    } else if (key === TValue.STEP) {
      validatedValue =
        roundedValue <= STEP_MIN_VALUE || roundedValue >= STEP_MAX_VALUE ? undefined : roundedValue;
    }

    updateFieldValueHandler(key, validatedValue);
  };

  return (
    <Box sx={{ display: 'flex', gap: 2, mt: 7.5 }}>
      <TextField
        helperText={`Value should be not smaller than ${MIN_VALUE}`}
        inputProps={{ min: MIN_VALUE }}
        label="Min Value"
        onChange={handleChange(TValue.MIN)}
        size="small"
        sx={{ background: globalStyles.mainColors.whiteColor, flexBasis: '40%' }}
        type="number"
        value={field.min ?? ''}
        variant="outlined"
      />

      <TextField
        helperText={`Value should be not bigger than ${MAX_VALUE}`}
        inputProps={{ max: MAX_VALUE }}
        label="Max Value"
        onChange={handleChange(TValue.MAX)}
        size="small"
        sx={{ background: globalStyles.mainColors.whiteColor, flexBasis: '40%' }}
        type="number"
        value={field.max ?? ''}
        variant="outlined"
      />

      <TextField
        helperText={`Value should be between ${STEP_MIN_VALUE} and ${STEP_MAX_VALUE}`}
        inputProps={{ max: STEP_MAX_VALUE, min: STEP_MIN_VALUE }}
        label="Step"
        onChange={handleChange(TValue.STEP)}
        size="small"
        sx={{ background: globalStyles.mainColors.whiteColor, flexBasis: '20%' }}
        type="number"
        value={field.step ?? ''}
        variant="outlined"
      />
    </Box>
  );
};

export default DataSourceDetailsFieldConfiguratorRangeSelect;
