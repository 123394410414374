import { Dialog } from '@a-type/ui/components';
import { useDispatch, useSelector } from '@a-type/ui/hooks';
import { dataSourcesService } from '@a-type/ui/services';
import { pageContentLoad, snackbarSuccessMessage } from '@a-type/ui/stores/actions';
import { Typography } from '@mui/material';

export interface DataSourceDetailsReindexDialogProps {
  reindexDialog: boolean;
  setReindexDialog: (value: boolean) => void;
}

const DataSourceDetailsReindexDialog = (props: DataSourceDetailsReindexDialogProps) => {
  const { reindexDialog, setReindexDialog } = props;
  const { currentDataSource } = useSelector((state) => state.dataSource);
  const dispatch = useDispatch();

  const reindexDataSource = async () => {
    if (currentDataSource && currentDataSource._id) {
      dispatch(pageContentLoad(false));

      const response = await dataSourcesService.reindex(currentDataSource._id);
      if (response?.status === 200) {
        setReindexDialog(false);
        dispatch(
          snackbarSuccessMessage(
            'Reindexing fields make take some time (up to 10 hours), so the data will not be aviable.',
          ),
        );
      }

      dispatch(pageContentLoad(true));
    }
  };

  return (
    <Dialog
      cancelText="Cancel"
      okText="Yes, Reindex"
      onCancel={() => setReindexDialog(false)}
      onClose={() => setReindexDialog(false)}
      onOk={reindexDataSource}
      open={reindexDialog}
      title="Reindex Data Source"
    >
      <Typography sx={{ fontSize: 20 }}>
        It may take several hours, and the data will not be available at that time. Do you want to
        continue?
      </Typography>
    </Dialog>
  );
};

export default DataSourceDetailsReindexDialog;
