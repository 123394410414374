import { IDataSourceField, IFilterModel, IFilterStringValue } from '@a-type/interfaces';
import { useDispatch, useSelector } from '@a-type/ui/hooks';
import { dataSourcesService } from '@a-type/ui/services';
import { setCount } from '@a-type/ui/stores/actions';
import globalStyles from '@a-type/ui/styles/global.styles';
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';

import SelectedValues from './SelectedValues.component';
import SelectedValuesControl from './SelectedValuesControl.component';

export interface MultiSelectFilterProps {
  field: IDataSourceField;
}

export interface LookupOption {
  label: string;
  state?: string;
  value: string;
}

const MultiSelectFilter = (props: MultiSelectFilterProps) => {
  const { field } = props;
  const { count, initLookup } = useSelector((state) => state.count);
  const { currentDataSource } = useSelector((state) => state.dataSource);
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState('');
  const [previousSearchText, setPreviousSearchText] = useState('');
  const [selectedOptions, setSelectedOptions] = useState<IFilterStringValue[]>([]);
  const [filteredOptions, setFilteredOptions] = useState<LookupOption[]>([]);
  const [allOptions, setAllOptions] = useState<LookupOption[]>([]);
  const [query, setQuery] = useState('');
  const lookupAbortController = useRef<AbortController>();

  const getOptions = async (search: string, signal: AbortSignal) => {
    if (!currentDataSource) return [];

    const res = await dataSourcesService.lookup(
      currentDataSource,
      field.name,
      search,
      false,
      signal,
    );
    if (res?.status === 200) {
      return [...res.data];
    }
    return [];
  };

  useEffect(() => {
    const timeOutId = setTimeout(() => setSearchText(query), 250);
    return () => clearTimeout(timeOutId);
  }, [query]);

  // Initialize filter
  useEffect(() => {
    if (!count?.filters) return;

    if (!count.filters[field.name]) {
      const filter = {
        _id: field.name,
        mode: 'add',
        name: field.displayName,
        price: field.price,
        sortOrder: field.sortOrder,
        type: field.dataType,
        units: field.units,
        values: [],
      } as IFilterModel;
      dispatch(setCount({ ...count, filters: { ...count.filters, [field.name]: filter } }));
    } else {
      setSelectedOptions(count.filters[field.name].values as IFilterStringValue[]);
    }
  }, [count]);

  useEffect(() => {
    if (!initLookup[field.name]) return;

    setAllOptions(initLookup[field.name]);
    setFilteredOptions(initLookup[field.name]);
  }, [initLookup, field]);

  useEffect(() => {
    if (field.prefetchAllValues) {
      const options = initLookup[field.name] || [];
      setFilteredOptions(
        options
          .filter((option: LookupOption) =>
            option.label.toLowerCase().includes(searchText.toLowerCase()),
          )
          .filter(
            (option: LookupOption) =>
              !selectedOptions.some((x: IFilterStringValue) => x.value === option.value),
          ),
      );
    } else if (previousSearchText === searchText) {
      setFilteredOptions(
        allOptions.filter(
          (option) => !selectedOptions.some((x: IFilterStringValue) => x.value === option.value),
        ),
      );
    } else if (!searchText) {
      setPreviousSearchText('');
      const options = initLookup[field.name] || [];
      setAllOptions(options);
      setFilteredOptions(
        options.filter(
          (option: LookupOption) =>
            !selectedOptions.some((x: IFilterStringValue) => x.value === option.value),
        ),
      );
    } else {
      setPreviousSearchText(searchText);

      if (lookupAbortController.current) {
        lookupAbortController.current.abort();
      }
      lookupAbortController.current = new AbortController();
      const { signal } = lookupAbortController.current;

      getOptions(searchText, signal).then((options) => {
        if (signal.aborted) return;

        setAllOptions(options);
        setFilteredOptions(
          options.filter(
            (option) => !selectedOptions.some((x: IFilterStringValue) => x.value === option.value),
          ),
        );
      });
    }
  }, [selectedOptions, searchText]);

  const handleSelectOption = (option: LookupOption) => {
    if (!count?.filters) return;

    if (selectedOptions.some((x: IFilterStringValue) => x.value === option.value)) return;
    const values = [...selectedOptions, { label: option.label, value: option.value }];
    dispatch(
      setCount({
        ...count,
        filters: {
          ...count.filters,
          [field.name]: {
            ...count.filters[field.name],
            values,
          },
        },
      }),
    );
  };

  return (
    <Box
      sx={{
        display: 'flex',
        height: 240,
        width: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexBasis: '50%',
          flexDirection: 'column',
          gap: 1,
          pt: 1.5,
          px: 1.5,
        }}
      >
        <TextField
          onChange={(e) => setQuery(e.target.value)}
          placeholder="Search"
          size="small"
          sx={{ width: '100%' }}
          value={query}
          variant="outlined"
        />
        <List
          sx={{
            flexGrow: 1,
            overflow: 'auto',
            p: 0,
            position: 'relative',
            width: '100%',
          }}
        >
          {filteredOptions.length > 0 &&
            filteredOptions.map((option) => (
              <ListItem
                key={option.value}
                sx={{
                  ':hover': {
                    backgroundColor: globalStyles.mainColors.distantWindChimeColor,
                  },
                  p: 0,
                }}
              >
                <ListItemButton onClick={() => handleSelectOption(option)} sx={{ p: 0 }}>
                  <ListItemText
                    sx={{
                      color: globalStyles.mainColors.blackColor,
                      my: '2px',
                      px: '12px',
                      py: '2px',
                    }}
                  >
                    <Typography component="span">{option.label}</Typography>
                  </ListItemText>
                </ListItemButton>
              </ListItem>
            ))}
        </List>
      </Box>

      <Box
        sx={{
          borderLeft: '1px solid #e0e0e0',
          display: 'flex',
          flexBasis: '50%',
          flexDirection: 'column',
          gap: 1,
          pt: 1.5,
          px: 1.5,
        }}
      >
        <SelectedValuesControl field={field} />
        <SelectedValues field={field} />
      </Box>
    </Box>
  );
};

export default MultiSelectFilter;
