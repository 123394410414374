import {
  AdminAppendDataDetailsPage,
  AdminAppendDataPage,
  CombinedSearchPage,
  ConfirmEmailPage,
  CreatePlanPage,
  FilterGroupPage,
  ForgetPasswordPage,
  LoginPage,
  Page404,
  PlanPage,
  PlansPage,
  RegisterPage,
  ResetPasswordPage,
  SettingsPage,
  TransactionsPage,
  UserDetailPage,
  UsersPage,
  ViewPage,
  ViewsPage,
} from '@a-type/ui/pages';
import CountsCreatePage from '@a-type/ui/pages/counts/CountsCreatePage.component';
import CountsListPage from '@a-type/ui/pages/counts/CountsListPage.component';
import CountsNarrowSearchPage from '@a-type/ui/pages/counts/CountsNarrowSearchPage.component';
import CountsUpdatePage from '@a-type/ui/pages/counts/CountsUpdatePage.component';
import ActivityPage from '@a-type/ui/pages/dashboard/ActivityPage.component';
import DataSourcesListPage from '@a-type/ui/pages/dashboard/DataSourcesListPage.component';
import HelpCenterComponent from '@a-type/ui/pages/HelpCenterPage.component';
import {
  AppendDataPage,
  EditListPage,
  ListsPage,
  TaxAssessorsDocumentPage,
  UploadListPage,
} from '@a-type/ui/pages/lists';
import BuyMoreDataPageComponent from '@a-type/ui/pages/lists/BuyMoreDataPage.component';
import BuyNewListPage from '@a-type/ui/pages/lists/BuyNewListPage.component';
import ListDocumentsPage from '@a-type/ui/pages/lists/ListDocumentsPage.component';
import { Route, Routes } from 'react-router-dom';

import { AccountDetailsPage } from '../pages/account/account-details.page';
import { AccountUpdatePlanPage } from '../pages/account/account-update-plan.page';
import { AccountPaymentHistoryPage } from '../pages/account/AccountPaymentHistoryPage.component';
import { AccountPlanAndBillingPage } from '../pages/account/AccountPlanAndBillingPage.component';
import AdminDataSourceDetailsPageComponent from '../pages/admin/dataSourceDetails/AdminDataSourceDetailsPage.component';
import AdminDataSourcesPageComponent from '../pages/admin/dataSourcesList/AdminDataSourcesPage.component';
import { IndividualSearchSettingsPage } from '../pages/admin/individual-search-settings/individual-search-settings.page';
import { BucketPage } from '../pages/bucket';
import { DashboardPage } from '../pages/dashboard/DashboardPage.component';
import { IndividualSearchPage } from '../pages/individual-search/individual-search.page';
import { SubscribePage } from '../pages/subscribe/subscribe.page';
import { AppRoutes } from './AppRoutes';
import {
  RouteAuthenticatedOrBlockedComponent,
  RouteSuperadminComponent,
  RouteUnauthenticatedOrBlockedComponent,
} from './RouteGuards.component';

const AppNavigationSwitchRouterComponent = () => {
  return (
    <Routes>
      <Route element={<DashboardPage />} path={AppRoutes.HomePage} />
      <Route element={<HelpCenterComponent />} path={AppRoutes.HelpPage} />
      <Route
        element={
          <RouteUnauthenticatedOrBlockedComponent>
            <LoginPage />
          </RouteUnauthenticatedOrBlockedComponent>
        }
        path={`${AppRoutes.LoginPage}`}
      />
      <Route
        element={
          <RouteUnauthenticatedOrBlockedComponent>
            <RegisterPage />
          </RouteUnauthenticatedOrBlockedComponent>
        }
        path={`${AppRoutes.RegistrationPage}`}
      />
      <Route
        element={
          <RouteUnauthenticatedOrBlockedComponent>
            <ConfirmEmailPage />
          </RouteUnauthenticatedOrBlockedComponent>
        }
        path={`${AppRoutes.ConfirmEmailPage}/:confirmAccountToken`}
      />
      <Route
        element={
          <RouteUnauthenticatedOrBlockedComponent>
            <ForgetPasswordPage />
          </RouteUnauthenticatedOrBlockedComponent>
        }
        path={AppRoutes.ForgetPasswordPage}
      />
      <Route
        element={
          <RouteUnauthenticatedOrBlockedComponent>
            <ResetPasswordPage />
          </RouteUnauthenticatedOrBlockedComponent>
        }
        path={`${AppRoutes.ResetPasswordPage}/:resetPasswordToken`}
      />
      <Route
        element={
          <RouteAuthenticatedOrBlockedComponent>
            <DataSourcesListPage />
          </RouteAuthenticatedOrBlockedComponent>
        }
        path={AppRoutes.DataSourcesPage}
      />
      <Route
        element={
          <RouteAuthenticatedOrBlockedComponent>
            <ActivityPage />
          </RouteAuthenticatedOrBlockedComponent>
        }
        path={AppRoutes.Activity}
      />
      <Route
        element={
          <RouteAuthenticatedOrBlockedComponent>
            <CountsListPage />
          </RouteAuthenticatedOrBlockedComponent>
        }
        path={AppRoutes.CountsList}
      />
      <Route
        element={
          <RouteAuthenticatedOrBlockedComponent>
            <CountsNarrowSearchPage />
          </RouteAuthenticatedOrBlockedComponent>
        }
        path={`${AppRoutes.CountsList}/:countsId`}
      />
      <Route
        element={
          <RouteAuthenticatedOrBlockedComponent>
            <CountsCreatePage />
          </RouteAuthenticatedOrBlockedComponent>
        }
        path={`${AppRoutes.CountsCreate}/:dataSourceId`}
      />
      <Route
        element={
          <RouteAuthenticatedOrBlockedComponent>
            <CountsUpdatePage />
          </RouteAuthenticatedOrBlockedComponent>
        }
        path={`${AppRoutes.CountsUpdate}/:countsId`}
      />
      <Route
        element={
          <RouteAuthenticatedOrBlockedComponent>
            <BuyNewListPage />
          </RouteAuthenticatedOrBlockedComponent>
        }
        path={`${AppRoutes.BuyListPage}/:countsId`}
      />
      <Route
        element={
          <RouteAuthenticatedOrBlockedComponent>
            <IndividualSearchPage />
          </RouteAuthenticatedOrBlockedComponent>
        }
        path={AppRoutes.IndividualSearchPage}
      />
      <Route
        element={
          <RouteAuthenticatedOrBlockedComponent>
            <BuyMoreDataPageComponent />
          </RouteAuthenticatedOrBlockedComponent>
        }
        path={`${AppRoutes.BuyMoreDataPage}/:listId`}
      />
      <Route
        element={
          <RouteAuthenticatedOrBlockedComponent>
            <ListsPage />
          </RouteAuthenticatedOrBlockedComponent>
        }
        path={AppRoutes.Lists}
      />
      <Route
        element={
          <RouteAuthenticatedOrBlockedComponent>
            <ListDocumentsPage />
          </RouteAuthenticatedOrBlockedComponent>
        }
        path={`${AppRoutes.Lists}/:listId`}
      />

      <Route
        element={
          <RouteAuthenticatedOrBlockedComponent>
            <EditListPage />
          </RouteAuthenticatedOrBlockedComponent>
        }
        path={`${AppRoutes.Lists}/:listId/edit`}
      />

      <Route
        element={
          <RouteAuthenticatedOrBlockedComponent>
            <TaxAssessorsDocumentPage />
          </RouteAuthenticatedOrBlockedComponent>
        }
        path={`${AppRoutes.Lists}/:listId/tax-assessor/:documentId`}
      />

      <Route
        element={
          <RouteAuthenticatedOrBlockedComponent>
            <TaxAssessorsDocumentPage />
          </RouteAuthenticatedOrBlockedComponent>
        }
        path={`${AppRoutes.Bucket}/tax-assessor/:documentId`}
      />

      <Route
        element={
          <RouteAuthenticatedOrBlockedComponent>
            <AppendDataPage />
          </RouteAuthenticatedOrBlockedComponent>
        }
        path={`${AppRoutes.Lists}/:listId/append`}
      />

      <Route
        element={
          <RouteAuthenticatedOrBlockedComponent>
            <AppendDataPage />
          </RouteAuthenticatedOrBlockedComponent>
        }
        path={`${AppRoutes.Lists}/:listId/append/:documentId`}
      />

      <Route
        element={
          <RouteAuthenticatedOrBlockedComponent>
            <BucketPage />
          </RouteAuthenticatedOrBlockedComponent>
        }
        path={AppRoutes.Bucket}
      />

      <Route
        element={
          <RouteAuthenticatedOrBlockedComponent>
            <UploadListPage />
          </RouteAuthenticatedOrBlockedComponent>
        }
        path={`${AppRoutes.Lists}/upload`}
      />
      <Route
        element={
          <RouteAuthenticatedOrBlockedComponent>
            <SubscribePage />
          </RouteAuthenticatedOrBlockedComponent>
        }
        path={AppRoutes.SubscribePage}
      />
      <Route
        element={
          <RouteAuthenticatedOrBlockedComponent>
            <AccountDetailsPage />
          </RouteAuthenticatedOrBlockedComponent>
        }
        path={AppRoutes.AccountDetailsPage}
      />
      <Route
        element={
          <RouteAuthenticatedOrBlockedComponent>
            <AccountPlanAndBillingPage />
          </RouteAuthenticatedOrBlockedComponent>
        }
        path={AppRoutes.AccountPlanBillingPage}
      />
      <Route
        element={
          <RouteAuthenticatedOrBlockedComponent>
            <AccountPaymentHistoryPage />
          </RouteAuthenticatedOrBlockedComponent>
        }
        path={AppRoutes.AccountPaymentsHistoryPage}
      />
      <Route
        element={
          <RouteAuthenticatedOrBlockedComponent>
            <AccountUpdatePlanPage />
          </RouteAuthenticatedOrBlockedComponent>
        }
        path={AppRoutes.AccountUpdatePlanPage}
      />
      <Route
        element={
          <RouteSuperadminComponent>
            <UsersPage />
          </RouteSuperadminComponent>
        }
        path={AppRoutes.AdminUsersPage}
      />
      <Route
        element={
          <RouteSuperadminComponent>
            <UserDetailPage />
          </RouteSuperadminComponent>
        }
        path={`${AppRoutes.AdminUsersPage}/:id`}
      />
      <Route
        element={
          <RouteSuperadminComponent>
            <TransactionsPage />
          </RouteSuperadminComponent>
        }
        path={AppRoutes.AdminPaymentsPage}
      />
      <Route
        element={
          <RouteSuperadminComponent>
            <AdminDataSourcesPageComponent />
          </RouteSuperadminComponent>
        }
        path={AppRoutes.AdminDataSourcesPage}
      />
      <Route
        element={
          <RouteSuperadminComponent>
            <AdminDataSourceDetailsPageComponent />
          </RouteSuperadminComponent>
        }
        path={`${AppRoutes.AdminDataSourcesPage}/:id`}
      />
      <Route
        element={
          <RouteSuperadminComponent>
            <AdminDataSourceDetailsPageComponent />
          </RouteSuperadminComponent>
        }
        path={`${AppRoutes.AdminDataSourcesPage}/api/:id`}
      />
      <Route
        element={
          <RouteSuperadminComponent>
            <CombinedSearchPage />
          </RouteSuperadminComponent>
        }
        path={`${AppRoutes.AdminCombinedSearchPage}/:type`}
      />
      <Route
        element={
          <RouteSuperadminComponent>
            <ViewsPage />
          </RouteSuperadminComponent>
        }
        path={AppRoutes.AdminViewsPage}
      />
      <Route
        element={
          <RouteSuperadminComponent>
            <IndividualSearchSettingsPage />
          </RouteSuperadminComponent>
        }
        path={AppRoutes.AdminIndividualSearchPage}
      />
      <Route
        element={
          <RouteSuperadminComponent>
            <ViewPage />
          </RouteSuperadminComponent>
        }
        path={`${AppRoutes.AdminViewsPage}/:id`}
      />
      <Route
        element={
          <RouteSuperadminComponent>
            <FilterGroupPage />
          </RouteSuperadminComponent>
        }
        path={AppRoutes.AdminFilterGroupsPage}
      />
      <Route
        element={
          <RouteSuperadminComponent>
            <PlansPage />
          </RouteSuperadminComponent>
        }
        path={AppRoutes.AdminPlansPage}
      />
      <Route
        element={
          <RouteSuperadminComponent>
            <CreatePlanPage />
          </RouteSuperadminComponent>
        }
        path={`${AppRoutes.AdminPlansPage}/new`}
      />
      <Route
        element={
          <RouteSuperadminComponent>
            <PlanPage />
          </RouteSuperadminComponent>
        }
        path={`${AppRoutes.AdminPlansPage}/:id`}
      />
      <Route
        element={
          <RouteSuperadminComponent>
            <AdminAppendDataPage />
          </RouteSuperadminComponent>
        }
        path={AppRoutes.AdminAppendDataPage}
      />
      <Route
        element={
          <RouteSuperadminComponent>
            <AdminAppendDataDetailsPage />
          </RouteSuperadminComponent>
        }
        path={`${AppRoutes.AdminAppendDataPage}/:id`}
      />
      <Route
        element={
          <RouteSuperadminComponent>
            <SettingsPage />
          </RouteSuperadminComponent>
        }
        path={AppRoutes.AdminSettingsPage}
      />
      <Route element={<Page404 />} path={AppRoutes.NotFoundPage} />
    </Routes>
  );
};

export default AppNavigationSwitchRouterComponent;
