import { Autocomplete, createFilterOptions, TextField } from '@mui/material';

interface IEmailsInputProps {
  emails: string[];
  label: string;
  onChange: (emails: string[]) => void;
}

const filter = createFilterOptions<string>();

export const EmailsInput: React.FC<IEmailsInputProps> = ({
  emails,
  label,
  onChange,
}: IEmailsInputProps) => {
  return (
    <Autocomplete
      clearOnBlur
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        const { inputValue } = params;

        // Suggest the creation of a new value
        const isExisting = options.some((option) => inputValue === option);
        if (
          inputValue !== '' &&
          !isExisting &&
          /^[A-Za-z0-9._%+-]+@[A-Za-z0-9-]+[.][A-Za-z.]{2,}$/.test(inputValue)
        ) {
          filtered.push(inputValue);
        }

        return filtered;
      }}
      freeSolo
      fullWidth
      handleHomeEndKeys
      multiple
      onChange={(event, value) => {
        onChange(value);
      }}
      options={emails}
      renderInput={(params) => (
        <TextField {...params} inputProps={{ ...params.inputProps, maxLength: 60 }} label={label} />
      )}
      renderOption={(props, option) => {
        const { ...optionProps } = props;
        return (
          <li {...optionProps} key={option}>
            {option}
          </li>
        );
      }}
      selectOnFocus
      value={emails}
    />
  );
};
