import { ICount, IDataSource, IDataSourceField, IInsight } from '@a-type/interfaces';
import { useDispatch } from '@a-type/ui/hooks';
import { PageLayout } from '@a-type/ui/layout';
import CountsNarrowSearch from '@a-type/ui/pages/counts/CountsNarrowSearch.component';
import { AppRoutes } from '@a-type/ui/router/AppRoutes';
import { dataSourcesService } from '@a-type/ui/services';
import { countsService } from '@a-type/ui/services/counts/counts.service';
import {
  pageContentLoad,
  resetCountState,
  setCount,
  setCurrentDataSource,
  setInsights,
  snackbarErrorMessage,
} from '@a-type/ui/stores/actions';
import { useGetFilterGroupsQuery } from '@a-type/ui/stores/apis';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const CountsNarrowSearchPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  useGetFilterGroupsQuery();

  const getDataSourceById = async (id: string) => {
    const response = await dataSourcesService.getById(id);
    if (response?.status === 200) {
      dispatch(setCurrentDataSource(response.data));
      return response.data;
    }

    dispatch(snackbarErrorMessage('Data source not found'));
    navigate(AppRoutes.HomePage);
    return null;
  };

  const getCountsById = async (id: string) => {
    const response = await countsService.getById(id);
    if (response?.status === 200) {
      dispatch(setCount(response.data));
      return { ...response.data };
    }

    dispatch(snackbarErrorMessage('Counts not found'));
    navigate(AppRoutes.HomePage);
    return null;
  };

  const getInsights = async (
    dataSource: IDataSource,
    count: ICount,
  ): Promise<IInsight | undefined> => {
    const response = await dataSourcesService.insights(dataSource, count);
    if (response?.status === 200) {
      return response.data;
    }

    return undefined;
  };

  useEffect(() => {
    dispatch(resetCountState());

    const locationArray = location.pathname.split('/');
    const id = locationArray[locationArray.length - 1];

    if (!id) {
      dispatch(snackbarErrorMessage('Counts not found'));
      navigate(AppRoutes.HomePage);
    } else {
      const loadCounts = async () => {
        dispatch(pageContentLoad(false));
        const counts = await getCountsById(id);
        if (!counts) {
          return;
        }

        // load data source
        const dataSource = await getDataSourceById(counts.dataSourceId!);
        if (!dataSource) {
          return;
        }

        const groupBy = [...dataSource.fields]
          .sort(
            (a: IDataSourceField, b: IDataSourceField) => Number(a.sortOrder) - Number(b.sortOrder),
          )
          .find((field) => field.isGroupedBy)?.name;
        if (groupBy) {
          counts.groupBy = groupBy;
          const insights = await getInsights(dataSource, counts as ICount);
          dispatch(setCount({ ...counts } as ICount));
          dispatch(setInsights(insights));
        } else {
          dispatch(setInsights(undefined));
        }

        dispatch(pageContentLoad(true));
      };

      loadCounts();
    }
  }, []);

  return (
    <PageLayout container>
      <CountsNarrowSearch />
    </PageLayout>
  );
};

export default CountsNarrowSearchPage;
