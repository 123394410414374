import { Dialog } from '@a-type/ui/components';
import { useDispatch, useSelector } from '@a-type/ui/hooks';
import { dataSourcesService } from '@a-type/ui/services';
import { pageContentLoad, snackbarSuccessMessage } from '@a-type/ui/stores/actions';
import { Typography } from '@mui/material';

export interface DataSourceDetailsBuildLookupsDialogProps {
  buildLookupsDialog: boolean;
  setBuildLookupsDialog: (value: boolean) => void;
}

const DataSourceDetailsBuildLookupsDialog = (props: DataSourceDetailsBuildLookupsDialogProps) => {
  const { buildLookupsDialog, setBuildLookupsDialog } = props;
  const { currentDataSource } = useSelector((state) => state.dataSource);
  const dispatch = useDispatch();

  const buildLookupsForDataSource = async () => {
    if (currentDataSource && currentDataSource._id) {
      dispatch(pageContentLoad(false));

      const response = await dataSourcesService.buildLookups(currentDataSource._id);
      if (response?.status === 200) {
        setBuildLookupsDialog(false);
        dispatch(
          snackbarSuccessMessage(
            'Building lookups make take some time, some multi select filters will not be available.',
          ),
        );
      }
      dispatch(pageContentLoad(true));
    }
  };

  return (
    <Dialog
      cancelText="Cancel"
      okText="Yes, Build Lookups"
      onCancel={() => setBuildLookupsDialog(false)}
      onClose={() => setBuildLookupsDialog(false)}
      onOk={buildLookupsForDataSource}
      open={buildLookupsDialog}
      title="Build Lookups for Data Source"
    >
      <Typography sx={{ fontSize: 20 }}>
        It may take several hours, and the multi select filters will not be available at that time.
        Do you want to continue?
      </Typography>
    </Dialog>
  );
};

export default DataSourceDetailsBuildLookupsDialog;
