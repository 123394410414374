import { IDocumentWithNavigation, IPaginationDto } from '@a-type/dtos';
import { IList } from '@a-type/interfaces';

import apiMiddleware from './api-middleware';

export const bucketApi = apiMiddleware.injectEndpoints({
  endpoints: (builder) => ({
    getBucket: builder.query<IList, void>({
      providesTags: [{ id: 'BUCKET', type: 'Bucket' as const }],
      query: () => ({
        url: `/bucket`,
      }),
    }),
    getBucketDocumentByIdWithNavigation: builder.query<IDocumentWithNavigation, string>({
      providesTags: [{ id: 'DOCUMENTS', type: 'Bucket' as const }],
      query: (documentId: string) => ({
        url: `/bucket/documents/${documentId}/navigation`,
      }),
    }),
    getBucketDocuments: builder.query<IPaginationDto<any>, { limit?: number; page?: number }>({
      providesTags: [{ id: 'DOCUMENTS', type: 'Bucket' as const }],
      query: ({ limit, page }) => ({
        params: { limit, page },
        url: `/bucket/documents`,
      }),
    }),
  }),
});

export const {
  useGetBucketDocumentByIdWithNavigationQuery,
  useGetBucketDocumentsQuery,
  useGetBucketQuery,
} = bucketApi;
