import { ICount, IDataSource, IDataSourceField, IInsightValue } from '@a-type/interfaces';
import { GridPagination } from '@a-type/ui/components';
import { useDispatch, useSelector } from '@a-type/ui/hooks';
import { dataSourcesService } from '@a-type/ui/services';
import {
  pageContentLoad,
  setCount,
  setInsights,
  setNarrowSearch,
  snackbarErrorMessage,
} from '@a-type/ui/stores/actions';
import globalStyles from '@a-type/ui/styles/global.styles';
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import {
  DataGrid,
  gridPageCountSelector,
  gridPageSelector,
  gridPageSizeSelector,
  GridRowId,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid';
import { useEffect, useState } from 'react';

const CountsNarrowSearchList = () => {
  const { count, insights } = useSelector((state) => state.count);
  const { currentDataSource } = useSelector((state) => state.dataSource);
  const dispatch = useDispatch();
  const [recordsCount, setRecordsCount] = useState<number>(
    insights?.count ?? count?.totalRecords ?? 0,
  );
  const [selected, setSelected] = useState<GridRowId[]>([]);
  const [groups, setGroups] = useState<
    {
      label: string;
      value: string;
    }[]
  >([]);
  const [groupBy, setGroupBy] = useState<string>('');
  const [groupByLabel, setGroupByLabel] = useState<string>('');
  const [searchText, setSearchText] = useState<string>('');
  const [rows, setRows] = useState<IInsightValue[]>([]);

  useEffect(() => {
    if (currentDataSource) {
      setGroups(
        currentDataSource.fields
          .filter((field: IDataSourceField) => field.isGroupedBy)
          .sort(
            (a: IDataSourceField, b: IDataSourceField) => Number(a.sortOrder) - Number(b.sortOrder),
          )
          .map((field: IDataSourceField) => ({
            label:
              field.price > 0
                ? `${field.displayName} (+${field.price} Credit / Record)`
                : field.displayName,
            value: field.name,
          })),
      );
    }
  }, [currentDataSource]);

  useEffect(() => {
    if (!count) return;

    if (!insights) {
      setRecordsCount(count.totalRecords || 0);
      dispatch(setCount({ ...count, totalRecords: count.totalRecords }));
      return;
    }

    if (selected.length === 0 || selected.length === insights.values.length) {
      setRecordsCount(insights.count);
      dispatch(setCount({ ...count, totalRecords: insights.count }));
    } else {
      const records = selected.reduce(
        (acc: number, item: GridRowId) =>
          acc + (insights.values.find((x) => x.value.toString() === item.toString())?.count ?? 0),
        0,
      );
      setRecordsCount(records);
      dispatch(setCount({ ...count, totalRecords: records }));
      dispatch(setNarrowSearch(selected.map((item: GridRowId) => item.toString())));
    }
  }, [selected]);

  useEffect(() => {
    if (!count?.groupBy || !groups.length) {
      return;
    }

    const group = groups.find((item) => item.value === count.groupBy);
    if (group) {
      setGroupBy(group.value);
      setGroupByLabel(group.label);
    }
  }, [count, groups]);

  useEffect(() => {
    if (!insights) return;

    if (searchText === '') {
      setRows(insights.values);
      return;
    }

    const filteredRows = insights.values.filter((row: IInsightValue) =>
      row.label.toLowerCase().includes(searchText.toLowerCase()),
    );

    setRows(filteredRows);
  }, [insights, searchText]);

  const getInsights = async (ds: IDataSource, c: ICount) => {
    const response = await dataSourcesService.insights(ds, c);
    if (response?.status === 200) {
      return response.data;
    }

    dispatch(snackbarErrorMessage('Insights not found'));
    return null;
  };

  const updateGroupByValueHandler = async (gb: string) => {
    if (!count || !currentDataSource) return;
    if (gb === count.groupBy) return;

    dispatch(pageContentLoad(false));
    setSearchText('');
    const newCounts = { ...count, groupBy: gb };
    const newInsights = await getInsights(currentDataSource, newCounts);
    if (newInsights) {
      setSelected([]);
      dispatch(setInsights(newInsights));
      dispatch(setCount({ ...newCounts }));
    }
    dispatch(pageContentLoad(true));
  };

  const usePaginator = () => {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);
    const pageSize = useGridSelector(apiRef, gridPageSizeSelector);

    return (
      <GridPagination
        count={insights?.values.length ?? 0}
        limit={pageSize}
        onChangePagination={(p, l) => {
          apiRef.current.setPage(p);
          apiRef.current.setPageSize(l);
        }}
        page={page}
        pages={pageCount}
      />
    );
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        height: '100%',
        width: '100%',
      }}
    >
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          gap: 2,
          justifyContent: 'space-between',
        }}
      >
        <Typography
          component="span"
          sx={{
            color: globalStyles.mainColors.sootyColor,
            fontSize: 16,
            fontWeight: 900,
          }}
        >
          Narrow further
        </Typography>

        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexGrow: 1,
            gap: 2,
            justifyContent: 'flex-end',
          }}
        >
          <FormControl sx={{ width: 200 }}>
            <InputLabel id="group-by-label">Group by</InputLabel>
            <Select
              disabled={groups.length === 1}
              label="Group by"
              labelId="group-by-label"
              onChange={(e: any) => {
                const { value } = e.target;
                updateGroupByValueHandler(value);
              }}
              size="small"
              sx={{ background: globalStyles.mainColors.whiteColor }}
              value={groups.length > 0 ? groupBy : 'no-filters'}
              variant="outlined"
            >
              {groups.length > 0 ? (
                groups.map((item: any) => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))
              ) : (
                <MenuItem disabled value="no-filters">
                  No filters available
                </MenuItem>
              )}
            </Select>
          </FormControl>
        </Box>
      </Box>

      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          gap: 2,
          justifyContent: 'space-between',
        }}
      >
        <TextField
          label="Search"
          onChange={(e) => setSearchText(e.target.value)}
          placeholder="Search"
          size="small"
          sx={{
            width: 300,
          }}
          value={searchText}
          variant="outlined"
        />

        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexGrow: 1,
            gap: 2,
            justifyContent: 'space-between',
          }}
        >
          <Typography
            component="span"
            sx={{
              color: globalStyles.mainColors.headTableGreyColor,
              fontSize: 14,
              fontWeight: 500,
            }}
          >
            {selected.length > 0
              ? `${selected.length.toLocaleString()} items selected`
              : 'No items selected'}
          </Typography>

          <Typography
            component="span"
            sx={{
              color: globalStyles.mainColors.headTableGreyColor,
              fontSize: 14,
              fontWeight: 500,
            }}
          >
            {`${recordsCount?.toLocaleString() || 0} Records`}
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flex: 1,
          height: '100%',
          justifyContent: 'center',
          position: 'relative',
          width: '100%',
        }}
      >
        {insights ? (
          <DataGrid
            checkboxSelection
            columns={[
              { field: 'label', flex: 1, headerName: groupByLabel, type: 'string' },
              { field: 'count', flex: 1, headerName: '# of Records', type: 'number' },
            ]}
            getRowId={(row) => row.value}
            onRowSelectionModelChange={(params) => {
              setSelected([...params]);
            }}
            rowHeight={40}
            rows={rows}
            rowSelectionModel={selected}
            slots={{
              pagination: usePaginator,
            }}
            sx={{
              height: 'calc(100vh - 420px)',
            }}
          />
        ) : (
          <Typography
            component="span"
            sx={{
              color: globalStyles.mainColors.sootyColor,
              fontSize: 24,
              fontWeight: 500,
            }}
          >
            There are no filters to display
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default CountsNarrowSearchList;
