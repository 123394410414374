import axiosBaseQuery from '@a-type/ui/stores/axios-base-query';
import { createApi } from '@reduxjs/toolkit/query/react';

export default createApi({
  baseQuery: axiosBaseQuery({ baseUrl: process.env.REACT_APP_API_HOST! }),
  endpoints: () => ({}),
  tagTypes: [
    'FilterGroup',
    'Settings',
    'Document',
    'DataSource',
    'DataSource/User',
    'AppendData',
    'AppendData/User',
    'CombinedSearch',
    'List',
    'Bucket',
    'Count',
    'Account',
    'Users',
    'Dictionaries',
    'Stripe/Product',
    'Stripe/PaymentMethod',
    'Stripe/PaymentHistory',
    'Stripe/Subscription',
    'TaxAssessors/Search',
    'IndividualSearch',
    'Transactions/User',
    'Transactions/All',
    'Views',
  ],
});
