import { AppRoutes } from '@a-type/ui/router/AppRoutes';
import globalStyles from '@a-type/ui/styles/global.styles';
import {
  Box,
  Divider,
  List,
  ListItemButton,
  ListItemText,
  Theme,
  Typography,
  useTheme,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

interface AdminNavItem {
  label: string;
  url: string;
}

interface AdminNavGroup {
  id: string;
  navs: AdminNavItem[];
}

export const AdminSidebar: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [active, setActive] = useState<string>('users');

  useEffect(() => {
    const url = location.pathname.split('/').slice(0, 3).join('/');
    setActive(url);
  }, [location]);

  const theme: Theme = useTheme();
  const themeStylePalette = theme.palette;

  const navs: AdminNavGroup[] = [
    {
      id: 'users',
      navs: [
        {
          label: 'Users',
          url: AppRoutes.AdminUsersPage,
        },
        {
          label: 'Transactions',
          url: AppRoutes.AdminPaymentsPage,
        },
      ],
    },
    {
      id: 'dataSources',
      navs: [
        {
          label: 'Data Sources',
          url: AppRoutes.AdminDataSourcesPage,
        },
        {
          label: 'Append Data',
          url: AppRoutes.AdminAppendDataPage,
        },
      ],
    },
    {
      id: 'search',
      navs: [
        {
          label: 'Combined Search',
          url: `${AppRoutes.AdminCombinedSearchPage}/attom`,
        },
        {
          label: 'Views',
          url: AppRoutes.AdminViewsPage,
        },
        {
          label: 'Individual Search',
          url: AppRoutes.AdminIndividualSearchPage,
        },
      ],
    },
    {
      id: 'dictionary',
      navs: [
        {
          label: 'Filter Groups',
          url: AppRoutes.AdminFilterGroupsPage,
        },
        {
          label: 'Subscription plans',
          url: AppRoutes.AdminPlansPage,
        },
        {
          label: 'Settings',
          url: AppRoutes.AdminSettingsPage,
        },
      ],
    },
  ];

  return (
    <Box
      sx={{
        [`& .MuiDrawer-paper`]: {
          boxSizing: 'border-box',
        },
        backgroundColor: 'none',
        flexShrink: 0,
        overflow: 'auto',
        py: 5,
        width: 255,
      }}
    >
      <Typography
        component="div"
        gutterBottom
        sx={{ fontSize: 24, fontWeight: 800, lineHeight: '28.13px', pl: 4 }}
        variant="h5"
      >
        Admin Panel
      </Typography>
      <List component="nav">
        {navs.map((navGroup, index) => (
          <Box key={navGroup.id}>
            {navGroup.navs.map((nav) => (
              <ListItemButton
                key={nav.url}
                onClick={() => {
                  navigate(nav.url);
                }}
                selected={nav.url.includes(active)}
                sx={{
                  color: nav.url.includes(active)
                    ? globalStyles.mainColors.blueColor
                    : themeStylePalette.text.secondary,
                  textTransform: 'uppercase',
                }}
              >
                <ListItemText
                  primary={nav.label}
                  primaryTypographyProps={{
                    fontSize: 15,
                    fontWeight: 700,
                    lineHeight: '17.58px',
                  }}
                  sx={{ pl: 2, py: 0.5 }}
                />
              </ListItemButton>
            ))}

            {index !== navs.length - 1 && <Divider key={`divider-${navGroup.id}`} />}
          </Box>
        ))}
      </List>
    </Box>
  );
};

export default AdminSidebar;
