import { UserRoles, UserStatus } from '@a-type/enums';
import { ImageUpload } from '@a-type/ui/components';
import { useDispatch } from '@a-type/ui/hooks';
import { AccountPageLayout } from '@a-type/ui/layout';
import {
  pageContentLoad,
  snackbarErrorMessage,
  snackbarSuccessMessage,
} from '@a-type/ui/stores/actions';
import { useGetUserInfoQuery, useUpdateImageMutation } from '@a-type/ui/stores/apis';
import globalStyles from '@a-type/ui/styles/global.styles';
import { getError } from '@a-type/ui/utils';
import { HttpsOutlined, NoAccountsOutlined, Person } from '@mui/icons-material';
import { Box, Button, Card, CardContent, Chip, Divider, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

import { ChangePassword } from './components/change-password/change-password.component';
import { DeleteUser } from './components/delete-user/delete-user.component';
import { UpdateAddressInfo } from './components/update-address-info/update-address-info.component';
import { UpdateContactForm } from './components/update-contact-info/update-contact-info.component';

export const AccountDetailsPage = () => {
  const dispatch = useDispatch();
  const { data: user, isFetching: isUserFetching } = useGetUserInfoQuery();
  const [updateImage, { isLoading: isImageUpdating }] = useUpdateImageMutation();
  const [showDeleteUser, setShowDeleteUser] = useState(false);
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [showUpdateContactInfo, setShowUpdateContactInfo] = useState(false);
  const [showUpdateAddressInfo, setShowUpdateAddressInfo] = useState(false);

  useEffect(() => {
    dispatch(pageContentLoad(!isUserFetching && !isImageUpdating));
  }, [isUserFetching, isImageUpdating]);

  const handleImageUpdate = async (imageUrl: string) => {
    const responce = await updateImage({ imageUrl });

    if (responce.error) {
      dispatch(snackbarErrorMessage(getError(responce.error) ?? 'Failed to upload image'));
    }

    if (responce.data) {
      dispatch(snackbarSuccessMessage('Image uploaded successfully'));
    }
  };

  const getStatusColor = (status: UserStatus) => {
    switch (status) {
      case UserStatus.ACTIVE:
        return globalStyles.mainColors.greenColor;
      case UserStatus.BLOCKED:
        return globalStyles.mainColors.redColor;
      case UserStatus.PENDING:
        return globalStyles.mainColors.blueColor;
      default:
        return globalStyles.mainColors.yellowColor;
    }
  };

  const getStatusLabel = (status: UserStatus) => {
    switch (status) {
      case UserStatus.ACTIVE:
        return 'Active';
      case UserStatus.BLOCKED:
        return 'Blocked';
      case UserStatus.PENDING:
        return 'Pending';
      default:
        return 'Unknown';
    }
  };

  const getRoleLabel = (role: UserRoles) => {
    switch (role) {
      case UserRoles.ADMIN:
        return 'Admin';
      case UserRoles.SUPERADMIN:
        return 'Super Admin';
      case UserRoles.USER:
        return 'User';
      default:
        return 'Unknown';
    }
  };

  const renderItem = (label: string, value?: string) => {
    return (
      <Box
        sx={{
          display: 'flex',
          gap: 1,
        }}
      >
        <Typography
          component="span"
          sx={{
            color: globalStyles.mainColors.sootyColor,
            fontSize: 17,
            fontWeight: 400,
            width: 150,
          }}
        >
          {label}
        </Typography>
        <Typography
          component="span"
          sx={{
            color: globalStyles.mainColors.sootyColor,
            flexGrow: 1,
            fontSize: 17,
            fontWeight: 600,
          }}
        >
          {value}
        </Typography>
      </Box>
    );
  };

  return (
    <AccountPageLayout container>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 4,
          height: '100%',
          width: '100%',
        }}
      >
        <Box
          sx={{
            alignItems: 'center',
            borderBottom: `1px solid ${globalStyles.mainColors.gainsboroColor}`,
            display: 'flex',
            gap: 1,
            pb: 1,
          }}
        >
          <Typography
            sx={{
              color: globalStyles.mainColors.sootyColor,
              fontSize: 24,
              fontWeight: 900,
            }}
          >
            Profile Details
          </Typography>
        </Box>

        {user && (
          <>
            <Box
              sx={{
                display: 'flex',
                gap: 4,
              }}
            >
              <Box
                sx={{
                  height: '132px',
                  position: 'relative',
                  width: '132px',
                }}
              >
                {user.imageUrl ? (
                  <img
                    alt={user.fullName}
                    src={user.imageUrl}
                    style={{
                      borderRadius: '100%',
                      height: '100%',
                      width: '100%',
                    }}
                  />
                ) : (
                  <Box
                    sx={{
                      alignItems: 'center',
                      bgcolor: globalStyles.mainColors.whiteSmokeColor,
                      borderRadius: '100%',
                      display: 'flex',
                      height: '100%',
                      justifyContent: 'center',
                      width: '100%',
                    }}
                  >
                    <Person
                      sx={{
                        color: globalStyles.mainColors.whiteColor,
                        fontSize: 90,
                      }}
                    />
                  </Box>
                )}

                <ImageUpload
                  height={132}
                  name={`user-${user._id}`}
                  shape="round"
                  sx={{
                    bottom: 0,
                    position: 'absolute',
                    right: 0,
                  }}
                  updateImage={handleImageUpdate}
                  width={132}
                />
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  flexGrow: 1,
                }}
              >
                <Box
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    gap: 2,
                    justifyContent: 'space-between',
                  }}
                >
                  <Box
                    sx={{
                      alignItems: 'center',
                      display: 'flex',
                      gap: 2,
                    }}
                  >
                    <Typography
                      sx={{
                        color: globalStyles.mainColors.sootyColor,
                        fontSize: 28,
                        fontWeight: 700,
                      }}
                    >
                      {user.fullName}
                    </Typography>

                    {user.status && (
                      <Chip
                        label={getStatusLabel(user.status)}
                        size="small"
                        sx={{
                          backgroundColor: getStatusColor(user.status),
                          color: globalStyles.mainColors.whiteColor,
                          fontSize: 13,
                          fontWeight: 400,
                        }}
                      />
                    )}
                  </Box>

                  <Box
                    sx={{
                      alignItems: 'center',
                      display: 'flex',
                      gap: 2,
                    }}
                  >
                    {user.createdAt && (
                      <Typography
                        component="span"
                        sx={{
                          color: globalStyles.navigation.textColor,
                          display: 'flex',
                          fontSize: 12,
                          fontWeight: 400,
                          gap: 1,
                          textTransform: 'uppercase',
                        }}
                      >
                        Created:&nbsp;
                        <Typography
                          component="span"
                          sx={{
                            color: globalStyles.mainColors.sootyColor,
                            fontSize: 12,
                            fontWeight: 500,
                          }}
                        >
                          {new Date(user.createdAt).toLocaleDateString()}
                        </Typography>
                      </Typography>
                    )}
                  </Box>
                </Box>

                {user.role && (
                  <Typography
                    component="span"
                    sx={{
                      color: globalStyles.navigation.textColor,
                      fontSize: 20,
                      fontWeight: 500,
                    }}
                  >
                    {getRoleLabel(user.role)}
                  </Typography>
                )}

                <Box
                  sx={{
                    display: 'flex',
                    gap: 2,
                    mt: 2,
                  }}
                >
                  <Button
                    onClick={() => setShowChangePassword(true)}
                    size="small"
                    startIcon={<HttpsOutlined />}
                    variant="outlined"
                  >
                    Change Password
                  </Button>
                  <Button
                    color="error"
                    onClick={() => setShowDeleteUser(true)}
                    size="small"
                    startIcon={<NoAccountsOutlined />}
                    variant="outlined"
                  >
                    Delete Account
                  </Button>
                </Box>
              </Box>
            </Box>

            <Box
              sx={{
                display: 'grid',
                gap: 4,
                gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
                width: '100%',
              }}
            >
              <Card variant="outlined">
                <CardContent>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 2,
                      pb: 1,
                      px: 1,
                    }}
                  >
                    <Box
                      sx={{
                        alignItems: 'center',
                        display: 'flex',
                        gap: 2,
                        justifyContent: 'space-between',
                      }}
                    >
                      <Typography
                        component="span"
                        sx={{
                          color: globalStyles.mainColors.sootyColor,
                          fontSize: 14,
                          fontWeight: 700,
                          textTransform: 'uppercase',
                        }}
                      >
                        Contact Information
                      </Typography>
                      <Button
                        onClick={() => setShowUpdateContactInfo(true)}
                        size="small"
                        variant="text"
                      >
                        Edit
                      </Button>
                    </Box>

                    <Divider sx={{ mb: 1 }} />

                    {renderItem('First Name', user.firstName)}
                    {renderItem('Last Name', user.lastName)}
                    {renderItem('Phone Number', user.mobile)}
                    {renderItem('Company Name', user.companyName)}
                    {renderItem('Email', user.email)}
                    {renderItem(
                      'Birth Date',
                      user.birthday ? new Date(user.birthday).toLocaleDateString() : '',
                    )}
                    {renderItem('Gender', user.gender)}
                  </Box>
                </CardContent>
              </Card>

              <Card variant="outlined">
                <CardContent>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 2,
                      pb: 1,
                      px: 1,
                    }}
                  >
                    <Box
                      sx={{
                        alignItems: 'center',
                        display: 'flex',
                        gap: 2,
                        justifyContent: 'space-between',
                      }}
                    >
                      <Typography
                        component="span"
                        sx={{
                          color: globalStyles.mainColors.sootyColor,
                          fontSize: 14,
                          fontWeight: 700,
                          textTransform: 'uppercase',
                        }}
                      >
                        Address Information
                      </Typography>
                      <Button
                        onClick={() => setShowUpdateAddressInfo(true)}
                        size="small"
                        variant="text"
                      >
                        Edit
                      </Button>
                    </Box>

                    <Divider sx={{ mb: 1 }} />

                    {renderItem('Address', user.address)}
                    {renderItem('Address 2', user.address2)}
                    {renderItem('City', user.city)}
                    {renderItem('State', user.state)}
                    {renderItem('Zip Code', user.zip)}
                    {renderItem('Country', user.country)}
                  </Box>
                </CardContent>
              </Card>
            </Box>

            {showDeleteUser && <DeleteUser setShow={setShowDeleteUser} show={showDeleteUser} />}
            {showChangePassword && (
              <ChangePassword setShow={setShowChangePassword} show={showChangePassword} />
            )}
            {showUpdateContactInfo && (
              <UpdateContactForm
                setShow={setShowUpdateContactInfo}
                show={showUpdateContactInfo}
                user={user}
              />
            )}
            {showUpdateAddressInfo && (
              <UpdateAddressInfo
                setShow={setShowUpdateAddressInfo}
                show={showUpdateAddressInfo}
                user={user}
              />
            )}
          </>
        )}
      </Box>
    </AccountPageLayout>
  );
};
