import Counts from '@a-type/ui/assets/icons/Counts.svg';
import Credits from '@a-type/ui/assets/icons/Credits.svg';
import Lists from '@a-type/ui/assets/icons/Lists.svg';
import { InsightCard } from '@a-type/ui/components';
import { useDispatch } from '@a-type/ui/hooks';
import { AppRoutes } from '@a-type/ui/router/AppRoutes';
import { countsService, listService } from '@a-type/ui/services';
import { changeExtraCreditPopup } from '@a-type/ui/stores/actions';
import { useGetUserBalanceQuery } from '@a-type/ui/stores/apis';
import globalStyles from '@a-type/ui/styles/global.styles';
import { Box, Icon } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const DashboardInsight = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [totalCounts, setTotalCounts] = useState(0);
  const [totalLists, setTotalLists] = useState(0);
  const { data: creditsBalance = 0 } = useGetUserBalanceQuery();

  useEffect(() => {
    // fetch total counts
    const fetchTotalCounts = async () => {
      const response = await countsService.getTotalCounts();
      if (response?.status === 200) {
        setTotalCounts(response.data);
      }
    };
    // fetch total lists
    const fetchTotalLists = async () => {
      const response = await listService.getTotalLists();
      if (response?.status === 200) {
        setTotalLists(response.data);
      }
    };

    fetchTotalCounts();
    fetchTotalLists();
  }, []);

  return (
    <Box
      sx={{
        display: 'grid',
        gap: 4,
        gridTemplateColumns: 'repeat(auto-fill, minmax(325px, 1fr))',
      }}
    >
      <InsightCard
        icon={
          <Icon
            sx={{
              color: globalStyles.mainColors.sootyColor,
              height: '28px',
              width: '28px',
            }}
          >
            <img alt="Counts" src={Counts} />
          </Icon>
        }
        insight={totalCounts.toLocaleString()}
        key="my-counts"
        onClick={() => navigate(AppRoutes.CountsList)}
        title="my counts"
      />

      <InsightCard
        icon={
          <Icon
            sx={{
              color: globalStyles.mainColors.sootyColor,
              height: '28px',
              width: '28px',
            }}
          >
            <img alt="Lists" src={Lists} />
          </Icon>
        }
        insight={totalLists.toLocaleString()}
        key="my-lists"
        onClick={() => navigate(AppRoutes.Lists)}
        title="my lists"
      />

      <InsightCard
        icon={
          <Icon
            sx={{
              color: globalStyles.mainColors.sootyColor,
              height: '28px',
              width: '28px',
            }}
          >
            <img alt="Credits" src={Credits} />
          </Icon>
        }
        insight={Number(creditsBalance).toLocaleString()}
        key="available-credits"
        onClick={() => dispatch(changeExtraCreditPopup(true))}
        title="available credits"
      />
    </Box>
  );
};

export default DashboardInsight;
