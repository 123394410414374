import { IDataSourceShort } from '@a-type/dtos';
import { ButtonDictionaryNames } from '@a-type/ui/constant/index.constant';
import { AppRoutes } from '@a-type/ui/router/AppRoutes';
import globalStyles from '@a-type/ui/styles/global.styles';
import { LabelsUtils } from '@a-type/ui/utils';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Box, Button, Card, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export interface DashboardDatabaseCardProps {
  dataSource: IDataSourceShort;
}

const DashboardDatabaseCard = (props: DashboardDatabaseCardProps) => {
  const { dataSource } = props;
  const navigate = useNavigate();

  return (
    <Card
      key={dataSource._id}
      sx={{
        borderRadius: 2,
        boxShadow: 'none',
        display: 'flex',
        flex: '0 1 312px',
        flexDirection: 'column',
      }}
    >
      <Box
        sx={{
          backgroundImage: `url(${dataSource.imageUrl})`,
          backgroundSize: 'cover',
          display: 'flex',
          flexDirection: 'column',
          height: 258,
        }}
      >
        <Box
          sx={{
            background: 'linear-gradient(to right, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4))',
            display: 'flex',
            flexDirection: 'column',
            gap: 5,
            height: '100%',
            p: 2,
            width: '100%',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              minHeight: 48,
            }}
          >
            {dataSource.totalRecords > 0 && (
              <>
                <Typography
                  sx={{
                    color: globalStyles.mainColors.whiteColor,
                    fontSize: '16px',
                    fontWeight: '700',
                  }}
                >
                  {LabelsUtils.getQuantityLabel(dataSource.totalRecords || 0)}
                </Typography>
                <Typography
                  sx={{
                    color: globalStyles.mainColors.whiteColor,
                    fontSize: '16px',
                    fontWeight: '400',
                  }}
                >
                  Records
                </Typography>
              </>
            )}
          </Box>

          <Typography
            sx={{
              color: globalStyles.mainColors.whiteColor,
              fontSize: '32px',
              fontWeight: '500',
            }}
          >
            {dataSource.name}
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          gap: 2,
          justifyContent: 'space-between',
          p: 2,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography
            sx={{
              color: globalStyles.mainColors.tranquilPondColor,
              fontSize: '16px',
              fontWeight: '400',
            }}
          >
            Last updated
          </Typography>
          <Typography
            sx={{
              color: globalStyles.mainColors.tranquilPondColor,
              fontSize: '16px',
              fontWeight: '700',
            }}
          >
            {dataSource.updatedAt && new Date(dataSource.updatedAt).toLocaleDateString()}
          </Typography>
        </Box>
        <Button
          endIcon={<ArrowForwardIcon />}
          onClick={() => {
            if (!dataSource._id) return;

            if (dataSource._id === 'individual-search') {
              navigate(AppRoutes.IndividualSearchPage);
              return;
            }

            navigate(`${AppRoutes.CountsCreate}/${dataSource._id}`);
          }}
          variant="contained"
        >
          {ButtonDictionaryNames.BUILD_LIST}
        </Button>
      </Box>
    </Card>
  );
};

export default DashboardDatabaseCard;
