import { ICount, IDataSourceField, IDataSourcePricing } from '@a-type/interfaces';
import { useDispatch, useSelector } from '@a-type/ui/hooks';
import { setCount } from '@a-type/ui/stores/actions';
import globalStyles from '@a-type/ui/styles/global.styles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  Box,
  Card,
  FormGroup,
  InputLabel,
  List,
  ListItem,
  styled,
  TextField,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';

import CountsFiltersList from './CountsFiltersList.component';

const CountsFilters = () => {
  const dispatch = useDispatch();
  const { count } = useSelector((state) => state.count);
  const { currentDataSource } = useSelector((state) => state.dataSource);
  const [name, setName] = useState(count?.name);
  const [isNameValid, setIsNameValid] = useState(true);

  const updateCountsFieldHandler = (field: string, value: any) => {
    const newCounts = { ...count, [field]: value } as ICount;
    dispatch(setCount(newCounts));
  };

  useEffect(() => {
    const timeOutId = setTimeout(() => updateCountsFieldHandler('name', name), 500);
    return () => clearTimeout(timeOutId);
  }, [name]);

  const updateCountsPricingPlanHandler = (plan: IDataSourcePricing) => {
    const newCounts = {
      ...count,
      pricingPlanFields: plan.includedFields,
      pricingPlanId: plan._id,
      pricingPlanName: plan.name,
      pricingPlanPrice: plan.price,
    } as ICount;
    dispatch(setCount(newCounts));
  };

  const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: globalStyles.mainColors.whiteColor,
      border: `1px solid ${globalStyles.mainColors.tranquilPondColor}`,
      borderRadius: '8px',
      color: globalStyles.mainColors.tranquilPondColor,
      fontSize: theme.typography.pxToRem(12),
      maxWidth: 220,
    },
  }));

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 3,
      }}
    >
      <FormGroup
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
        }}
      >
        <InputLabel
          htmlFor="list-name"
          sx={{
            color: globalStyles.mainColors.blackColor,
            fontWeight: 'bold',
          }}
        >
          List Name
        </InputLabel>
        <TextField
          error={!isNameValid}
          helperText={
            !isNameValid
              ? 'Name is required and can contain only letters, numbers, spaces, dashes, and underscores "_".'
              : ''
          }
          id="list-name"
          onChange={(e) => {
            setName(e.target.value);
            setIsNameValid(/^[a-zA-Z0-9_-\s]*$/.test(e.target.value));
          }}
          value={name}
          variant="outlined"
        />
      </FormGroup>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
        }}
      >
        <Typography
          sx={{
            color: globalStyles.mainColors.blackColor,
            fontWeight: 'bold',
          }}
        >
          Select Plan
        </Typography>
        <Box
          sx={{
            display: 'grid',
            gap: 2,
            gridTemplateColumns: 'repeat(auto-fill, minmax(230px, 1fr))',
            width: '100%',
          }}
        >
          {currentDataSource?.pricing.map((plan: IDataSourcePricing) => (
            <Card
              key={plan._id}
              onClick={() => updateCountsPricingPlanHandler(plan)}
              sx={{
                background:
                  plan?._id === count?.pricingPlanId
                    ? globalStyles.mainColors.blueColor
                    : globalStyles.mainColors.whiteColor,
                border:
                  plan?._id === count?.pricingPlanId
                    ? `2px solid ${globalStyles.mainColors.blueColor}`
                    : `0.5px solid ${globalStyles.mainColors.silverColor}`,
                borderRadius: 2,
                boxShadow:
                  'rgb(0 0 0 / 6%) 0px 3px 6px -1px, rgb(0 0 0 / 6%) 0px 0px 0px 0px, rgb(0 0 0 / 6%) 0px 0px 0px 0px',
                boxSizing: 'border-box',
                cursor: 'pointer',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Box
                sx={{
                  backgroundImage: `url(${plan.imageUrl})`,
                  backgroundSize: 'cover',
                  display: 'flex',
                  flexDirection: 'column',
                  height: 149,
                }}
              >
                <Box
                  sx={{
                    background: 'linear-gradient(to right, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4))',
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    justifyContent: 'center',
                    p: 2,
                    position: 'relative',
                    width: '100%',
                  }}
                >
                  <Typography
                    sx={{
                      color: globalStyles.mainColors.whiteColor,
                      fontSize: '18px',
                      fontWeight: '700',
                    }}
                  >
                    {plan.name}
                  </Typography>

                  <HtmlTooltip
                    title={
                      <Box
                        sx={{
                          alignContent: 'start',
                          display: 'flex',
                          flexDirection: 'column',
                          px: 1,
                          py: 0.5,
                        }}
                      >
                        {plan.includedFields && plan.includedFields.length > 0 ? (
                          <>
                            <Typography
                              component="span"
                              sx={{
                                fontSize: '12px',
                                fontWeight: 700,
                              }}
                            >
                              Data included:
                            </Typography>
                            <List
                              sx={{
                                listStylePosition: 'inside',
                                listStyleType: 'disc',
                                m: 0,
                                pl: 1,
                              }}
                            >
                              {plan.includedFields.map((field) => (
                                <ListItem
                                  key={field}
                                  sx={{
                                    display: 'list-item',
                                    fontSize: '12px',
                                    fontWeight: 400,
                                    m: 0,
                                    p: 0,
                                    pb: 0.25,
                                  }}
                                >
                                  {
                                    currentDataSource.fields.find(
                                      (x: IDataSourceField) => x.name === field,
                                    )?.displayName
                                  }
                                </ListItem>
                              ))}
                            </List>
                          </>
                        ) : null}

                        {plan.excludedFields && plan.excludedFields.length > 0 ? (
                          <>
                            <Typography
                              component="span"
                              sx={{
                                fontSize: '12px',
                                fontWeight: 700,
                              }}
                            >
                              Data excluded:
                            </Typography>
                            <List
                              sx={{
                                listStylePosition: 'inside',
                                listStyleType: 'disc',
                                m: 0,
                                pl: 1,
                              }}
                            >
                              {plan.excludedFields.map((field) => (
                                <ListItem
                                  key={field}
                                  sx={{
                                    display: 'list-item',
                                    fontSize: '12px',
                                    fontWeight: 400,
                                    m: 0,
                                    p: 0,
                                    pb: 0.25,
                                  }}
                                >
                                  {
                                    currentDataSource.fields.find(
                                      (x: IDataSourceField) => x.name === field,
                                    )?.displayName
                                  }
                                </ListItem>
                              ))}
                            </List>
                          </>
                        ) : null}
                      </Box>
                    }
                  >
                    <InfoOutlinedIcon
                      fontSize="small"
                      sx={{
                        color: globalStyles.mainColors.whiteColor,
                        position: 'absolute',
                        right: 16,
                        top: 16,
                      }}
                    />
                  </HtmlTooltip>
                </Box>
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  gap: 1,
                  justifyContent: 'space-between',
                  p: 1.5,
                }}
              >
                <Typography
                  sx={{
                    color:
                      plan?._id === count?.pricingPlanId
                        ? globalStyles.mainColors.whiteColor
                        : globalStyles.mainColors.blackColor,
                    fontSize: '12px',
                    fontWeight: 700,
                  }}
                >
                  <Typography
                    component="span"
                    sx={{
                      color:
                        plan?._id === count?.pricingPlanId
                          ? globalStyles.mainColors.whiteColor
                          : globalStyles.mainColors.greenColor,
                      fontSize: '12px',
                      fontWeight: 700,
                    }}
                  >
                    {plan.price} Credits&nbsp;
                  </Typography>
                  / Record
                </Typography>

                {plan?._id === count?.pricingPlanId && (
                  <CheckCircleIcon
                    fontSize="small"
                    sx={{ color: globalStyles.mainColors.whiteColor }}
                  />
                )}
              </Box>
            </Card>
          ))}
        </Box>
      </Box>

      <CountsFiltersList />
    </Box>
  );
};

export default CountsFilters;
