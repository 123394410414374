import { IIndividualSearchInfo, IIndividualSearchResult } from '@a-type/dtos';

import apiMiddleware from './api-middleware';

export const taxAssessorsApi = apiMiddleware.injectEndpoints({
  endpoints: (builder) => ({
    buyRecord: builder.mutation<
      {
        documentId: string;
      },
      string
    >({
      invalidatesTags: [{ type: 'Bucket' }],
      query: (id) => ({ method: 'GET', url: `/tax-assessors/buy/${id}` }),
    }),
    getIndividualSearchInfo: builder.query<IIndividualSearchInfo, void>({
      providesTags: [{ id: 'INFO', type: 'IndividualSearch' }],
      query: () => ({ url: '/tax-assessors/info' }),
    }),
    searchTaxAssessors: builder.query<
      IIndividualSearchResult,
      {
        query: string;
      }
    >({
      providesTags: [{ id: 'TAX_ASSESSORS', type: 'TaxAssessors/Search' }],
      query: ({ query }) => ({ params: { query }, url: '/tax-assessors' }),
    }),
  }),
});

export const { useBuyRecordMutation, useGetIndividualSearchInfoQuery, useSearchTaxAssessorsQuery } =
  taxAssessorsApi;
