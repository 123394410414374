import { DataSourceDateOptions } from '@a-type/enums';
import {
  IDataSourceField,
  IFilterDateRangeValue,
  IFilterModel,
  IFilterStringRangeValue,
} from '@a-type/interfaces';
import { useDispatch, useSelector } from '@a-type/ui/hooks';
import { setCount } from '@a-type/ui/stores/actions';
import globalStyles from '@a-type/ui/styles/global.styles';
import { LabelsUtils } from '@a-type/ui/utils';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { Box, Button, IconButton } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { useEffect, useState } from 'react';

import SelectedValuesControl from './SelectedValuesControl.component';

export interface DateRangeSelectFilterProps {
  field: IDataSourceField;
}

interface DateBorders {
  maxDate?: Date;
  minDate?: Date;
}

interface ValidationResult {
  isValid: boolean;
  message?: string;
}

const DateRangeSelectFilter = (props: DateRangeSelectFilterProps) => {
  const { field } = props;
  const { count } = useSelector((state) => state.count);
  const dispatch = useDispatch();
  const [selectedOptions, setSelectedOptions] = useState<IFilterDateRangeValue[]>([]);
  const [dateBorders, setDateBorders] = useState<DateBorders>({});

  const MAX_RANGE_COUNT = 5;

  // Initialize filter
  useEffect(() => {
    if (!count || !count.filters) return;

    if (!count.filters[field.name!]) {
      const filter = {
        _id: field.name!,
        mode: 'add',
        name: field.displayName!,
        price: field.price,
        sortOrder: field.sortOrder,
        type: field.dataType!,
        units: field.units,
        values: [],
      } as IFilterModel;
      dispatch(setCount({ ...count, filters: { ...count.filters, [field.name!]: filter } }));
    } else if (count.filters[field.name!].values.length !== selectedOptions.length) {
      setSelectedOptions(
        count.filters[field.name!].values.map((x) => {
          const value = x as IFilterStringRangeValue;
          return {
            _id: value._id || new Date().getTime().toString(),
            from: value.from ? new Date(value.from) : null,
            label: value.label,
            to: value.to ? new Date(value.to) : null,
          } as IFilterDateRangeValue;
        }),
      );
    }
  }, [count]);

  const noAvailableRangeAmount = (): boolean => {
    return selectedOptions.length >= MAX_RANGE_COUNT;
  };

  const addRange = () => {
    if (noAvailableRangeAmount()) return;

    setSelectedOptions([
      ...selectedOptions,
      {
        _id: new Date().getTime().toString(),
        from: null,
        label: '',
        to: null,
      } as IFilterDateRangeValue,
    ]);
  };

  const removeRange = (index: number) => {
    setSelectedOptions(selectedOptions.filter((_, i) => i !== index));
  };

  const validateDateOptions = (option: IFilterDateRangeValue): ValidationResult => {
    if (option.from === null && option.to === null)
      return { isValid: false, message: 'Both dates cannot be empty' };

    if (option.from && option.to && option.from > option.to)
      return { isValid: false, message: 'Start date cannot be after end date' };

    if (
      option.from &&
      ((dateBorders.maxDate && option.from > dateBorders.maxDate) ||
        (dateBorders.minDate && option.from < dateBorders.minDate))
    )
      return { isValid: false, message: 'Start date out of range' };
    if (
      option.to &&
      ((dateBorders.maxDate && option.to > dateBorders.maxDate) ||
        (dateBorders.minDate && option.to < dateBorders.minDate))
    )
      return { isValid: false, message: 'End date out of range' };

    return { isValid: true };
  };

  const updateRange = (index: number, key: 'from' | 'to', value?: Date | null) => {
    const currentOption = selectedOptions[index];

    const newFrom = key === 'from' ? value : currentOption.from;
    const newTo = key === 'to' ? value : currentOption.to;

    const label = LabelsUtils.getDateRangeLabel(newFrom, newTo);

    setSelectedOptions(
      selectedOptions.map((option, i) =>
        i === index ? { ...option, from: newFrom, label, to: newTo } : option,
      ),
    );
  };

  const formatedDate = (date: Date) => {
    try {
      const offset = date.getTimezoneOffset();
      date.setMinutes(date.getMinutes() - offset);
      return date.toISOString().slice(0, 10);
    } catch {
      return null;
    }
  };

  const updateFilter = (values: IFilterDateRangeValue[]) => {
    if (!count || !count.filters || !count.filters[field.name!]) return;

    // validate date ranges
    const areAllOptionsValid = values.every((option) => validateDateOptions(option).isValid);
    if (!areAllOptionsValid) return;

    dispatch(
      setCount({
        ...count,
        filters: {
          ...count.filters,
          [field.name!]: {
            ...count.filters[field.name!],
            values: values.map(
              (x) =>
                ({
                  _id: x._id,
                  from: x.from ? formatedDate(x.from) : null,
                  label: x.label,
                  to: x.to ? formatedDate(x.to) : null,
                }) as IFilterStringRangeValue,
            ),
          },
        },
      }),
    );
  };

  const setDateOptions = () => {
    const today = new Date();

    switch (field.dateOptions) {
      case DataSourceDateOptions.FUTURE_DATE:
        setDateBorders({
          maxDate: undefined,
          minDate: today,
        });
        break;

      case DataSourceDateOptions.PAST_DATE:
        setDateBorders({
          maxDate: today,
          minDate: undefined,
        });
        break;

      default:
        setDateBorders({
          maxDate: undefined,
          minDate: undefined,
        });
        break;
    }
  };

  useEffect(() => {
    setDateOptions();

    const timeOutId = setTimeout(() => updateFilter(selectedOptions), 500);
    return () => clearTimeout(timeOutId);
  }, [selectedOptions]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        height: 'auto',
        justifyContent: 'space-between',
        p: 1.5,
        width: '100%',
      }}
    >
      <SelectedValuesControl field={field} />

      {selectedOptions.map((option, index) => {
        const validationResult = validateDateOptions(option);

        return (
          <Box
            key={`date-range-${option._id}`}
            sx={{ alignItems: 'center', display: 'flex', gap: 1, justifyContent: 'space-between' }}
          >
            <DatePicker
              maxDate={option.to ? option.to : dateBorders.maxDate}
              minDate={dateBorders.minDate}
              onChange={(date) => updateRange(index, 'from', date)}
              slotProps={{
                textField: {
                  error: !validationResult.isValid,
                  helperText: !validationResult.isValid ? validationResult.message : '',
                  label: 'From',
                  size: 'small',
                  sx: { flexGrow: 1 },
                  variant: 'outlined',
                },
              }}
              value={option.from}
            />

            <DatePicker
              maxDate={dateBorders.maxDate}
              minDate={option.from ? option.from : dateBorders.minDate}
              onChange={(date) => updateRange(index, 'to', date)}
              slotProps={{
                textField: {
                  error: !validationResult.isValid,
                  helperText: !validationResult.isValid ? validationResult.message : '',
                  label: 'To',
                  size: 'small',
                  sx: { flexGrow: 1 },
                  variant: 'outlined',
                },
              }}
              value={option.to}
            />

            <IconButton
              onClick={() => removeRange(index)}
              sx={{ mb: !validationResult.isValid ? 3 : 0, p: 0.5 }}
            >
              <CancelIcon sx={{ color: globalStyles.mainColors.grey74Color, fontSize: '18px' }} />
            </IconButton>
          </Box>
        );
      })}

      <Box sx={{ display: 'flex' }}>
        <Button
          color="info"
          disabled={noAvailableRangeAmount()}
          onClick={addRange}
          size="small"
          startIcon={<AddCircleIcon />}
        >
          Add a range
        </Button>
      </Box>
    </Box>
  );
};

export default DateRangeSelectFilter;
