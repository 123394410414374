import { ITaxAssessorShort } from '@a-type/dtos';
import { blue, transparent } from '@a-type/ui/styles/theme/colors';
import { LocationOn } from '@mui/icons-material';
import { Box, Tooltip } from '@mui/material';
import { AdvancedMarker, Map, useMap } from '@vis.gl/react-google-maps';
import { FC, useEffect, useState } from 'react';

interface IIndividualSearchMapProps {
  hoverPropertyId?: string;
  properties: ITaxAssessorShort[];
  setHoverPropertyId: (id: string | undefined) => void;
}

export const IndividualSearchMap: FC<IIndividualSearchMapProps> = ({
  hoverPropertyId,
  properties,
  setHoverPropertyId,
}: IIndividualSearchMapProps) => {
  const map = useMap();
  const [defaultCenter, setDefaultCenter] = useState({ lat: 44.9778, lng: -93.265 });

  // Try to get the user's location
  useEffect(() => {
    if (!navigator.geolocation) return;

    navigator.permissions.query({ name: 'geolocation' }).then((result) => {
      if (result.state === 'granted' || result.state === 'prompt') {
        navigator.geolocation.getCurrentPosition((position) => {
          setDefaultCenter({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });

          if (map && properties.length === 0) {
            map.setCenter({
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            });
          }
        });
      }
    });
  }, []);

  // Fit the map to the bounds of the properties
  useEffect(() => {
    if (!map) return;
    if (!properties.length) return;

    const bounds = new window.google.maps.LatLngBounds();
    properties
      .filter((property) => property.latitude && property.longitude)
      .forEach((property) => {
        bounds.extend({ lat: +property.latitude, lng: +property.longitude });
      });

    map.fitBounds(bounds, 50);
  }, [map, properties]);

  return (
    <Box
      className="google-map-wrapper"
      sx={{
        height: '100%',
        width: '100%',
      }}
    >
      <Map
        defaultCenter={defaultCenter}
        defaultZoom={12}
        disableDefaultUI
        mapId="INDIVIDUAL_SEARCH_MAP"
        maxZoom={18}
      >
        {properties
          .filter((property) => property.latitude && property.longitude)
          .map((property) => (
            <AdvancedMarker
              key={property.id}
              onMouseEnter={() => setHoverPropertyId(property.id)}
              onMouseLeave={() => setHoverPropertyId(undefined)}
              position={{ lat: +property.latitude, lng: +property.longitude }}
              style={{
                cursor: 'pointer',
              }}
              zIndex={hoverPropertyId === property.id ? 1 : 0}
            >
              <Tooltip key={property.id} title={property.address}>
                <Box
                  sx={{
                    alignItems: 'center',
                    backgroundColor: 'blue.05',
                    borderRadius: '50%',
                    display: 'flex',
                    flexDirection: 'column',
                    height: 40,
                    justifyContent: 'center',
                    outline: hoverPropertyId === property.id ? '6px solid' : 'none',
                    outlineColor: transparent(blue['05'], 0.3),
                    width: 40,
                  }}
                >
                  <LocationOn
                    sx={{
                      color: 'gray.00',
                      fontSize: 24,
                    }}
                  />
                </Box>
              </Tooltip>
            </AdvancedMarker>
          ))}
      </Map>
    </Box>
  );
};
