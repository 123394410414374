import { IDocument, IList } from '@a-type/interfaces';
import { AppRoutes } from '@a-type/ui/router/AppRoutes';
import globalStyles from '@a-type/ui/styles/global.styles';
import { Box, Button, List } from '@mui/material';
import { APIProvider } from '@vis.gl/react-google-maps';
import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { RowsAndColumnsBuilderMethods } from '../../../utils/RowsAndColumnsBuilder.utils';
import { TaxAssessorsDocumentFields } from '../../enums/tax-assessors-document-fields.enum';
import { TaxAssessorsDocumentLabels } from '../../enums/tax-assessors-document-labels.enum';
import { ITaxAssessorsDocumentField } from '../../types/tax-assessors-document-field.interface';
import { TaxAssessorsDocumentField } from './tax-assessors-document-field.component';
import { TaxAssessorsDocumentMap } from './tax-assessors-document-map.component';

interface ITaxAssessorsDocumentInfoProps {
  document: IDocument;
  list: IList;
  next: null | string;
  previous: null | string;
}

const TaxAssessorsInfoFields = [
  TaxAssessorsDocumentFields.PropertyAddressFull,
  TaxAssessorsDocumentFields.OwnerTypeDescription1,
  TaxAssessorsDocumentFields.PartyOwner1NameFull,
  TaxAssessorsDocumentFields.PartyOwner2NameFull,
  TaxAssessorsDocumentFields.PartyOwner3NameFull,
  TaxAssessorsDocumentFields.PartyOwner4NameFull,
  TaxAssessorsDocumentFields.TaxAssessedValueTotal,
  TaxAssessorsDocumentFields.PropertyUseGroup,
  TaxAssessorsDocumentFields.PropertyUseStandardized,
];

export const TaxAssessorsDocumentInfo: FC<ITaxAssessorsDocumentInfoProps> = ({
  document,
  list,
  next,
  previous,
}: ITaxAssessorsDocumentInfoProps) => {
  const navigate = useNavigate();
  const [properties, setProperties] = useState<ITaxAssessorsDocumentField[]>([]);
  const [coordinates, setCoordinates] = useState<[number, number] | undefined>();

  useEffect(() => {
    if (!document) return;
    if (!list) return;

    const latitude = Number(document.data[TaxAssessorsDocumentFields.PropertyLatitude]);
    const longitude = Number(document.data[TaxAssessorsDocumentFields.PropertyLongitude]);

    if (latitude && longitude) {
      setCoordinates([latitude, longitude]);
    } else {
      setCoordinates(undefined);
    }

    if (!list.fieldMetadata) return;

    setProperties(
      TaxAssessorsInfoFields.filter((field) => list.fieldMetadata![field])
        .map((field) => ({
          key: field,
          label: TaxAssessorsDocumentLabels[field],
          sortOrder: list.fieldMetadata![field].sortOrder,
          type: list.fieldMetadata![field].dataType,
          units: list.fieldMetadata![field].units,
          value: RowsAndColumnsBuilderMethods.access(field, document.data),
        }))
        .filter((field) => field.value !== undefined)
        .map((field) => ({
          ...field,
          value: RowsAndColumnsBuilderMethods.renderValue(field.value, field.type),
        }))
        .map((field) => {
          let { value } = field;

          switch (field.units) {
            case '$':
              value = `$${value}`;
              break;
            case '%':
              value = `${value}%`;
              break;
            default:
              break;
          }

          return {
            ...field,
            value,
          };
        }),
    );
  }, [document, list]);

  return (
    <Box
      sx={{
        alignItems: 'stretch',
        display: 'flex',
        gap: 2,
      }}
    >
      {coordinates && (
        <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY!} libraries={['marker']}>
          <TaxAssessorsDocumentMap coordinates={coordinates} />
        </APIProvider>
      )}

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          width: '100%',
        }}
      >
        <List
          sx={{
            border: `1px solid ${globalStyles.mainColors.gainsboroColor}`,
            borderRadius: 2,
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
          }}
        >
          {properties.map((field) => (
            <TaxAssessorsDocumentField field={field} key={field.key} />
          ))}
        </List>

        <Box
          sx={{
            display: 'flex',
            gap: 3,
            justifyContent: 'flex-end',
          }}
        >
          <Button
            color="primary"
            disabled={!previous}
            onClick={() => navigate(`${AppRoutes.Lists}/${list._id}/tax-assessor/${previous}`)}
            size="small"
            sx={{
              minWidth: '112px',
            }}
            variant="outlined"
          >
            Previous
          </Button>
          <Button
            color="primary"
            disabled={!next}
            onClick={() => navigate(`${AppRoutes.Lists}/${list._id}/tax-assessor/${next}`)}
            size="small"
            sx={{
              minWidth: '112px',
            }}
            variant="outlined"
          >
            Next
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
