import { ICount } from '@a-type/interfaces';
import { Dialog, GridPagination } from '@a-type/ui/components';
import { countsService } from '@a-type/ui/services/counts/counts.service';
import { pageContentLoad, snackbarSuccessMessage } from '@a-type/ui/stores/actions';
import { useGetUserDataSourcesQuery } from '@a-type/ui/stores/apis';
import { HttpStatus } from '@a-type/ui/types';
import { Typography } from '@mui/material';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import CountsEmptyListComponent from './components/list/CountsEmptyList.component';
import CountsListHeaderComponent from './components/list/CountsListHeader.component';
import CountsListTableHeaderComponent from './components/list/CountsListTableHeader.component';
import CountsListTableItemComponent from './components/list/CountsListTableItem.component';

const useStateCallback = (initialState: any) => {
  const [state, setState] = useState(initialState);
  const cbRef = useRef<any>(null); // init mutable ref container for callbacks

  const setStateCallback = useCallback((s: any, cb: any) => {
    cbRef.current = cb; // store current, passed callback in ref
    setState(s);
  }, []); // keep object reference stable, exactly like `useState`

  useEffect(() => {
    // cb.current is `null` on initial render,
    // so we only invoke callback on state *updates*
    if (cbRef.current) {
      cbRef.current(state);
      cbRef.current = null; // reset callback after execution
    }
  }, [state]);

  return [state, setStateCallback];
};

const DEFAULT_PAGE = 0;
const DEFAULT_LIMIT = 25;

export const CountsList = () => {
  const [counts, setCounts] = useState<ICount[]>([]);
  const [pagination, setPagination] = useStateCallback({
    limit: DEFAULT_LIMIT,
    page: DEFAULT_PAGE,
  });
  const [count, setCount] = useState(0);
  const [pages, setPages] = useState(0);
  const { data: dataSources } = useGetUserDataSourcesQuery();
  const [onDeleteConfirmation, setOnDeleteConfirmation] = useState(false);
  const [deleteCount, setDeleteCount] = useState<ICount | null>(null);

  const dispatch = useDispatch();

  const getAllCountsCallback = useCallback(
    async (p: any) => {
      dispatch(pageContentLoad(false));

      const response = await countsService.getAll(p.page, p.limit);

      if (response?.status === HttpStatus.OK) {
        const { data } = response;
        setCounts(data.items);
        setCount(data.count);
        setPages(data.pages);
      }

      dispatch(pageContentLoad(true));
    },
    [dispatch],
  );

  useEffect(() => {
    getAllCountsCallback({ limit: pagination.limit, page: pagination.page });
  }, []);

  const onChangePagination = (page: number, limit: number) => {
    setPagination(
      {
        limit: limit || DEFAULT_LIMIT,
        page: page || DEFAULT_PAGE,
      },
      () => {
        getAllCountsCallback({ limit, page });
      },
    );
  };
  const deleteCountCallback = async (id: string) => {
    dispatch(pageContentLoad(false));

    const response = await countsService.deleteById(id);

    if (response?.status === HttpStatus.OK) {
      dispatch(snackbarSuccessMessage(`Count ${deleteCount?.name} deleted successfully`));
      getAllCountsCallback({ limit: pagination.limit, page: pagination.page });
    }

    setOnDeleteConfirmation(false);
    setDeleteCount(null);

    dispatch(pageContentLoad(true));
  };

  const hasAccess = (c: ICount) => {
    return (c && dataSources?.some((source) => source._id === c.dataSourceId)) || false;
  };

  return (
    <>
      <CountsListHeaderComponent />
      {!counts?.length ? (
        <CountsEmptyListComponent />
      ) : (
        <>
          <CountsListTableHeaderComponent />
          {counts.map((c: ICount, index: number) => {
            return (
              <CountsListTableItemComponent
                count={c}
                hasAccess={hasAccess(c)}
                index={index}
                key={c._id}
                onDelete={() => {
                  setDeleteCount(c);
                  setOnDeleteConfirmation(true);
                }}
              />
            );
          })}
          <GridPagination
            count={count}
            limit={pagination.limit}
            onChangePagination={onChangePagination}
            page={pagination.page}
            pages={pages}
          />
        </>
      )}

      {onDeleteConfirmation && (
        <Dialog
          cancelText="Cancel"
          okText="Yes, Delete"
          onCancel={() => setOnDeleteConfirmation(false)}
          onClose={() => setOnDeleteConfirmation(false)}
          onOk={() => deleteCount?._id && deleteCountCallback(deleteCount._id)}
          open={onDeleteConfirmation}
          size="sm"
          title="Delete Confirmation"
        >
          <Typography sx={{ fontSize: 16, fontWeight: 400, width: '100%' }}>
            Are You sure You want to{' '}
            <Typography component="span" sx={{ fontWeight: 500 }}>
              {deleteCount?.name}
            </Typography>{' '}
            this Count?
          </Typography>
        </Dialog>
      )}
    </>
  );
};

export default CountsList;
