import { IIndividualSearchSettings, ISettings } from '@a-type/interfaces';

import apiMiddleware from './api-middleware';

export const settingsApi = apiMiddleware.injectEndpoints({
  endpoints: (builder) => ({
    getIndividualSearchSettings: builder.query<IIndividualSearchSettings, void>({
      providesTags: [{ id: 'INDIVIDUAL_SEARCH', type: 'Settings' as const }],
      query: () => ({ url: '/admin/settings/individual-search' }),
    }),
    getSettings: builder.query<ISettings, void>({
      providesTags: [{ id: 'GLOBAL', type: 'Settings' as const }],
      query: () => ({ url: '/admin/settings' }),
    }),
    updateIndividualSearchSettings: builder.mutation<
      IIndividualSearchSettings,
      IIndividualSearchSettings
    >({
      invalidatesTags: [{ id: 'INDIVIDUAL_SEARCH', type: 'Settings' as const }],
      query: (data) => ({
        data,
        method: 'POST',
        url: `/admin/settings/individual-search`,
      }),
    }),
    updateSettings: builder.mutation<ISettings, ISettings>({
      invalidatesTags: [{ id: 'GLOBAL', type: 'Settings' as const }],
      query: (data) => ({
        data,
        method: 'POST',
        url: `/admin/settings`,
      }),
    }),
  }),
});

export const {
  useGetIndividualSearchSettingsQuery,
  useGetSettingsQuery,
  useUpdateIndividualSearchSettingsMutation,
  useUpdateSettingsMutation,
} = settingsApi;
