import { IUserAccessSettings, IUserInfo } from '@a-type/dtos';
import { useDispatch } from '@a-type/ui/hooks';
import {
  pageContentLoad,
  snackbarErrorMessage,
  snackbarSuccessMessage,
} from '@a-type/ui/stores/actions';
import {
  useAppendTypesDictionaryQuery,
  useDataSourceDictionaryQuery,
  useGetAccessSettingsQuery,
  useGetViewsQuery,
  useUpdateAccessSettingsMutation,
} from '@a-type/ui/stores/apis';
import globalStyles from '@a-type/ui/styles/global.styles';
import { getError } from '@a-type/ui/utils';
import { Box, Button, Checkbox, FormControlLabel, Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';

interface IUserAccessSettingsProps {
  user: IUserInfo;
}

export const UserAccessSettings: FC<IUserAccessSettingsProps> = ({
  user,
}: IUserAccessSettingsProps) => {
  const dispatch = useDispatch();
  const { data: datasources } = useDataSourceDictionaryQuery();
  const { data: appends } = useAppendTypesDictionaryQuery();
  const { data: views } = useGetViewsQuery();
  const { data: userAccessSettings, isLoading: userAccessSettingsLoading } =
    useGetAccessSettingsQuery(user._id, {
      skip: !user._id,
    });
  const [updateAccessSettings, { isLoading: updateAccessSettingsLoading }] =
    useUpdateAccessSettingsMutation();
  const [accessSettings, setAccessSettings] = useState<IUserAccessSettings>({
    appends: [],
    canAccessIndividualSearch: false,
    databases: [],
    views: [],
  });

  useEffect(() => {
    dispatch(pageContentLoad(!userAccessSettingsLoading && !updateAccessSettingsLoading));
  }, [userAccessSettingsLoading]);

  useEffect(() => {
    if (userAccessSettings) {
      setAccessSettings(userAccessSettings);
    }
  }, [userAccessSettings]);

  const handleUpdateAccessSettings = async () => {
    const result = await updateAccessSettings({
      data: accessSettings,
      id: user._id,
    });

    if (result.data) {
      dispatch(snackbarSuccessMessage('User access settings updated successfully'));
    }

    if (result.error) {
      dispatch(
        snackbarErrorMessage(getError(result.error) ?? 'Error while updating access settings'),
      );
    }
  };

  const updateDatabaseAccess = (databaseId: string, access: boolean) => {
    const items = access
      ? [...accessSettings.databases, databaseId]
      : accessSettings.databases.filter((id) => id !== databaseId);

    setAccessSettings({ ...accessSettings, databases: items });
  };

  const updateAppendAccess = (appendId: string, access: boolean) => {
    const items = access
      ? [...accessSettings.appends, appendId]
      : accessSettings.appends.filter((id) => id !== appendId);

    setAccessSettings({ ...accessSettings, appends: items });
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        gap: 2,
        justifyContent: 'space-between',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexGrow: 1,
          gap: 4,
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            minWidth: 250,
          }}
        >
          <Typography
            component="span"
            sx={{
              color: globalStyles.mainColors.sootyColor,
              fontSize: 24,
              fontWeight: 700,
              mb: 1,
            }}
          >
            Databases
          </Typography>
          {datasources?.map((ds) => (
            <FormControlLabel
              control={
                <Checkbox
                  checked={accessSettings.databases.includes(ds.key)}
                  onChange={(e) => updateDatabaseAccess(ds.key, e.target.checked)}
                />
              }
              key={ds.key}
              label={ds.value}
              sx={{
                color: globalStyles.mainColors.sootyColor,
                fontSize: 16,
                fontWeight: 500,
              }}
            />
          ))}
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            minWidth: 250,
          }}
        >
          <Typography
            component="span"
            sx={{
              color: globalStyles.mainColors.sootyColor,
              fontSize: 24,
              fontWeight: 700,
              mb: 1,
            }}
          >
            Append Data
          </Typography>
          {appends?.map((append) => (
            <FormControlLabel
              control={
                <Checkbox
                  checked={accessSettings.appends.includes(append.key)}
                  onChange={(e) => updateAppendAccess(append.key, e.target.checked)}
                />
              }
              key={append.key}
              label={append.value}
              sx={{
                color: globalStyles.mainColors.sootyColor,
                fontSize: 16,
                fontWeight: 500,
              }}
            />
          ))}
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            minWidth: 250,
          }}
        >
          <Typography
            component="span"
            sx={{
              color: globalStyles.mainColors.sootyColor,
              fontSize: 24,
              fontWeight: 700,
              mb: 1,
            }}
          >
            Views
          </Typography>
          {views?.map((view) => (
            <FormControlLabel
              control={
                <Checkbox
                  checked={accessSettings.views.includes(view._id)}
                  onChange={(e) =>
                    setAccessSettings({
                      ...accessSettings,
                      views: e.target.checked
                        ? [...accessSettings.views, view._id]
                        : accessSettings.views.filter((id) => id !== view._id),
                    })
                  }
                />
              }
              key={view._id}
              label={view.name}
              sx={{
                color: globalStyles.mainColors.sootyColor,
                fontSize: 16,
                fontWeight: 500,
              }}
            />
          ))}

          <Typography
            component="span"
            sx={{
              color: globalStyles.mainColors.sootyColor,
              fontSize: 18,
              fontWeight: 700,
              mb: 1,
              mt: 2,
            }}
          >
            Individual Search
          </Typography>
          <FormControlLabel
            control={
              <Checkbox
                checked={accessSettings.canAccessIndividualSearch}
                onChange={(e) =>
                  setAccessSettings({
                    ...accessSettings,
                    canAccessIndividualSearch: e.target.checked,
                  })
                }
              />
            }
            key="individualSearch"
            label="Can Access"
            sx={{
              color: globalStyles.mainColors.sootyColor,
              fontSize: 16,
              fontWeight: 500,
            }}
          />
        </Box>
      </Box>

      <Box
        sx={{
          display: 'flex',
          mb: 2,
        }}
      >
        <Button
          color="primary"
          disabled={updateAccessSettingsLoading}
          onClick={handleUpdateAccessSettings}
          sx={{
            minWidth: 160,
          }}
          variant="contained"
        >
          Update
        </Button>
      </Box>
    </Box>
  );
};
