import {
  DataSourceDslStatus,
  DataSourceUpdateStrategy,
  DataSourceUploadStrategy,
  TargetType,
} from '@a-type/enums';
import { IDataSource, IDataSourceField } from '@a-type/interfaces';
import { useDispatch, useSelector } from '@a-type/ui/hooks';
import { dataSourcesService } from '@a-type/ui/services';
import {
  pageContentLoad,
  resetDataSource,
  setCurrentDataSource,
  setDataSources,
  setFiltersComponents,
} from '@a-type/ui/stores/actions';
import globalStyles from '@a-type/ui/styles/global.styles';
import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import ApiDataSourceDetailsComponent from './ApiDataSourceDetails.component';
import DataSourceDetailsButtonsComponent from './components/DataSourceDetailsButtons.component';
import DataSourceDetailsStatusLineComponent from './components/DataSourceDetailsStatusLine.component';
import DataSourceDetailsStepsNavigation from './components/DataSourceDetailsStepsNavigation.component';
import DataSourceDetailsTitleComponent from './components/DataSourceDetailsTitleComponent.component';
import FileDataSourceDetailsComponent from './FileDataSourceDetails.component';
import { DATA_SOURCE_TABS, DataSourceTypes } from './utils/DataSourceDetailsProps';

const DataSourceDetails = () => {
  const { currentDataSource, dataSources } = useSelector((state) => state.dataSource);
  const dispatch = useDispatch();
  const location = useLocation();

  const [activeStep, setActiveStep] = useState(0);
  const [currentStep, setCurrentStep] = useState<DataSourceTypes>(DATA_SOURCE_TABS[0]);
  const [stepsHistory, setStepsHistory] = useState<DataSourceTypes[]>([DATA_SOURCE_TABS[0]]);

  const [isBasicInfoValid, setIsBasicInfoValid] = useState(false);
  const [isMappingValid, setIsMappingValid] = useState(false);
  const [isPricingValid, setIsPricingValid] = useState(false);

  const checkExistNameAndCreateNew: any = (
    dataSourceNames: any,
    defaultNameNewDataSource: string,
    i = 1,
  ) => {
    const isExist = dataSourceNames.filter((item: string) => item === defaultNameNewDataSource);

    if (isExist.length) {
      const name = `${defaultNameNewDataSource} ${i}`;
      return checkExistNameAndCreateNew(dataSourceNames, name, i);
    }
    return defaultNameNewDataSource;
  };

  const creatNewDataSource = () => {
    const dataSourceNames = dataSources.map((dataSource) => dataSource.name);
    const defaultNameNewDataSource = 'New Data Source';
    const newName = checkExistNameAndCreateNew(dataSourceNames, defaultNameNewDataSource);

    dispatch(
      setCurrentDataSource({
        _id: '0',
        attomPrefixComponent: '',
        csvDelimiter: '","',
        description: '',
        fields: [],
        history: [],
        imageUrl: null,
        indexFields: [],
        isApi: false,
        isAttomDataSource: false,
        isView: false,
        lookups: [],
        name: newName,
        pricing: [],
        progress: 0,
        selectUploadStrategy: DataSourceUploadStrategy.MANUALLY_UPLOAD,
        sftpData: {
          history: [],
          host: '',
          password: '',
          uploadDirectoryPath: '',
          user: '',
        },
        status: DataSourceDslStatus.PROCESSING,
        targetType: TargetType.CUSTOMER,
        totalRecords: 0,
        updateStrategy: DataSourceUpdateStrategy.UPDATE_ADD,
      } as IDataSource),
    );
  };

  const getDataSourceById = async (id: string) => {
    dispatch(pageContentLoad(false));
    const response = await dataSourcesService.getById(id);
    dispatch(resetDataSource());

    if (response?.status === 200) {
      const examples: any[] = [];
      if (!response.data.isApi && response.data._id !== '0') {
        const r = await dataSourcesService.getExamples(response.data._id);
        if (r?.status === 200) {
          examples.push(...r.data);
        }
      }

      // Update sort order for fields and set examples
      let sortOrder = 0;
      const fields = (response.data.fields || [])
        .sort((a: IDataSourceField, b: IDataSourceField) => a.sortOrder - b.sortOrder)
        .map((field: IDataSourceField) => {
          sortOrder += 1;
          return {
            ...field,
            examples: examples.map((example: any) => example[field.name]),
            sortOrder,
          };
        })
        .sort((a: IDataSourceField, b: IDataSourceField) => a.sortOrder - b.sortOrder);

      dispatch(setCurrentDataSource({ ...response.data, fields }));
    }
    dispatch(pageContentLoad(true));
  };

  const getAllDataSources = async () => {
    const response = await dataSourcesService.getAll();
    if (response?.status === 200) {
      dispatch(setDataSources([...response.data]));
    }
  };

  const loadFilterComponents = async () => {
    const response = await dataSourcesService.getFilterComponents();
    if (response?.status === 200) {
      dispatch(setFiltersComponents([...response.data]));
    }
  };

  useEffect(() => {
    setCurrentStep(DATA_SOURCE_TABS[activeStep]);
  }, [activeStep]);

  useEffect(() => {
    const locationArray = location.pathname.split('/');
    const id = locationArray[locationArray.length - 1];
    if (id === 'new') {
      creatNewDataSource();
    } else {
      getDataSourceById(id);
      // set full steps history for existing data source
      setStepsHistory(DATA_SOURCE_TABS);
    }

    // load all data sources
    getAllDataSources();
    // load components for filter
    loadFilterComponents();
  }, []);

  return (
    currentDataSource && (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          height: '100%',
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            background: globalStyles.mainColors.whiteColor,
            borderRadius: 1,
            height: 'auto',
          }}
        >
          {currentDataSource.isApi ? null : <DataSourceDetailsStatusLineComponent />}

          <Box sx={{ p: 2, pb: 5, pt: 2 }}>
            <DataSourceDetailsTitleComponent />

            <DataSourceDetailsStepsNavigation
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              setStepsHistory={setStepsHistory}
              stepsHistory={stepsHistory}
            />

            {currentDataSource.isApi ? (
              <ApiDataSourceDetailsComponent
                currentStep={currentStep}
                setBasicInfoValid={setIsBasicInfoValid}
                setMappingValid={setIsMappingValid}
                setPricingValid={setIsPricingValid}
              />
            ) : (
              <FileDataSourceDetailsComponent
                currentStep={currentStep}
                setBasicInfoValid={setIsBasicInfoValid}
                setMappingValid={setIsMappingValid}
                setPricingValid={setIsPricingValid}
              />
            )}
          </Box>
        </Box>
        <DataSourceDetailsButtonsComponent
          activeStep={activeStep}
          currentStep={currentStep}
          isBasicInfoValid={isBasicInfoValid}
          isMappingValid={isMappingValid}
          isPricingValid={isPricingValid}
          setActiveStep={setActiveStep}
          setStepsHistory={setStepsHistory}
          stepsHistory={stepsHistory}
        />
      </Box>
    )
  );
};

export default DataSourceDetails;
