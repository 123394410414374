import globalStyles from '@a-type/ui/styles/global.styles';
import { Box } from '@mui/material';

interface PageLayoutProps {
  children: React.ReactNode;
  container?: boolean;
}

export const PageLayout: React.FC<PageLayoutProps> = ({
  children,
  container = false,
}: PageLayoutProps) => {
  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100%',
        overflow: 'auto',
        px: 8,
        py: 3,
        width: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          height: 'auto',
          maxWidth: 1384,
          width: '100%',
        }}
      >
        {container ? (
          <Box
            sx={{
              background: globalStyles.mainColors.whiteColor,
              borderRadius: 2,
              display: 'flex',
              flexDirection: 'column',
              height: 'auto',
              minHeight: '100%',
              px: 4,
              py: 3,
              width: '100%',
            }}
          >
            {children}
          </Box>
        ) : (
          children
        )}
      </Box>
    </Box>
  );
};

export default PageLayout;
