import { IIndividualSearchSettings } from '@a-type/interfaces';
import { ImageUpload } from '@a-type/ui/components';
import { useDispatch } from '@a-type/ui/hooks';
import { AdminPageLayout } from '@a-type/ui/layout';
import {
  pageContentLoad,
  snackbarErrorMessage,
  snackbarSuccessMessage,
} from '@a-type/ui/stores/actions';
import {
  useGetIndividualSearchSettingsQuery,
  useGetViewsQuery,
  useUpdateIndividualSearchSettingsMutation,
} from '@a-type/ui/stores/apis';
import globalStyles from '@a-type/ui/styles/global.styles';
import { getError } from '@a-type/ui/utils';
import { Box, Button, FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

export const IndividualSearchSettingsPage = () => {
  const dispatch = useDispatch();
  const { data, isLoading: isSettingsLoading } = useGetIndividualSearchSettingsQuery();
  const { data: views, isLoading: isViewsLoading } = useGetViewsQuery();
  const [updateSettings, { isLoading: isUpdatingSettings }] =
    useUpdateIndividualSearchSettingsMutation();
  const [settings, setSettings] = useState<IIndividualSearchSettings>({
    canAccess: false,
    imageUrl: '',
    viewId: '',
  });

  useEffect(() => {
    dispatch(pageContentLoad(!isSettingsLoading && !isUpdatingSettings && !isViewsLoading));
  }, [isSettingsLoading, isUpdatingSettings, isViewsLoading]);

  useEffect(() => {
    if (data) {
      setSettings(data);
    }
  }, [data]);

  const handleUpdateSettings = async () => {
    const result = await updateSettings(settings);

    if (result.data) {
      dispatch(snackbarSuccessMessage('Individual search settings updated successfully'));
    }

    if (result.error) {
      dispatch(
        snackbarErrorMessage(
          getError(result.error) || 'Failed to update individual search settings',
        ),
      );
    }
  };

  return (
    <AdminPageLayout container>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
        }}
      >
        <Box
          sx={{
            alignItems: 'center',
            borderBottom: `1px solid ${globalStyles.mainColors.gainsboroColor}`,
            display: 'flex',
            gap: 1,
            pb: 1,
          }}
        >
          <Typography
            sx={{
              color: globalStyles.mainColors.sootyColor,
              fontSize: 24,
              fontWeight: 900,
            }}
          >
            Individual Search Settings
          </Typography>
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexGrow: 1,
            gap: 2,
            justifyContent: 'space-between',
            mt: 2,
            width: '100%',
          }}
        >
          <FormControl
            sx={{
              flexGrow: 1,
            }}
          >
            <InputLabel id="view">View</InputLabel>
            <Select
              disabled={isSettingsLoading || isUpdatingSettings}
              label="View"
              labelId="view"
              onChange={(e) => setSettings({ ...settings, viewId: e.target.value })}
              value={settings.viewId}
            >
              {views?.map((view) => (
                <MenuItem key={view._id} value={view._id}>
                  {view.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Box
            sx={{
              backgroundImage: `url(${settings.imageUrl})`,
              backgroundSize: 'cover',
              border: `1px solid ${globalStyles.mainColors.gainsboroColor}`,
              borderRadius: 2,
              height: 300,
              position: 'relative',
              width: 400,
            }}
          >
            <ImageUpload
              disabled={isSettingsLoading || isUpdatingSettings}
              height={300}
              key="individual-search-image"
              name="individual-search-image"
              sx={{
                bottom: 4,
                position: 'absolute',
                right: 4,
              }}
              updateImage={(imageUrl) => setSettings({ ...settings, imageUrl })}
              width={400}
            />
          </Box>
        </Box>

        <Box
          sx={{
            display: 'flex',
            gap: 2,
            justifyContent: 'flex-end',
            mt: 2,
          }}
        >
          <Button
            color="primary"
            disabled={isSettingsLoading || isUpdatingSettings}
            onClick={handleUpdateSettings}
            sx={{
              minWidth: 160,
            }}
            variant="contained"
          >
            Update
          </Button>
        </Box>
      </Box>
    </AdminPageLayout>
  );
};
